import { Epic, ofType } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, mergeMap, switchMap } from 'rxjs/operators'
import dataService from '../../../services/dataService'
import { RootState } from '../../../store/indexReducers'
import { hideScreenLoaderAction } from '../Loader/loaderActions'
import { showNotification } from '../Notification/notificationActions'
import {
    cancelDowngradeFailedAction,
    cancelDowngradeSuccessAction,
    cancelSubscriptionFailedAction,
    cancelSubscriptionSuccessAction,
    getClientSecretFailedAction,
    getClientSecretSuccessAction,
    getTiersFailedAction,
    getTiersSuccessAction,
    getSubscriptionInfoAction,
    getSubscriptionInfoFailedAction,
    getSubscriptionInfoSuccessAction,
    requestSubscriptionActionFailed,
    requestSubscriptionActionSuccess,
    sendPaymentMethodSuccessAction,
    setActivatedAccount,
    setCurrentStepAction,
    updateLicenseActionFailed,
    updateLicenseActionSuccess,
} from './licensesActions'
import { LicensesTypes } from './licensesTypes'
import { getLicenses, setSession } from '../session/sessionActions'
import { noPaymentMethod, onPremise } from '../../../data/globalVar'
import { subscriptionSteps } from './licenses.constants'
import localStorageService from '../../../services/localStorageService'

export const getProductsEpic: Epic<any, any, RootState> = (action$, state$) => {
    return action$.pipe(
        ofType(LicensesTypes.GET_TIERS),
        switchMap((action: any): any => {
            return dataService.getTiers().pipe(
                mergeMap((res: any) => {
                    return of(getTiersSuccessAction(res.response))
                }),
                catchError((error): any => of(getTiersFailedAction()))
            )
        })
    )
}

export const getCurrentSubscriptionInfoEpic: Epic<any, any, RootState> = (
    action$,
    state$
) => {
    return action$.pipe(
        ofType(LicensesTypes.GET_CURRENT_SUBSCRIPTION_INFO),
        switchMap((action: any): any => {
            return from(dataService.getSubscriptionInfo()).pipe(
                mergeMap((response: any) => {
                    return of(
                        getSubscriptionInfoSuccessAction(response?.response)
                    )
                }),
                catchError((error): any =>
                    of(
                        getSubscriptionInfoFailedAction(),
                        showNotification(
                            'subscription.getLicenseInfoError',
                            'error',
                            '',
                            error?.status
                        )
                    )
                )
            )
        })
    )
}

export const updateLicenseEpic: Epic<any, any, RootState> = (
    action$,
    state$
) => {
    return action$.pipe(
        ofType(LicensesTypes.UPDATE_LICENSE),
        switchMap((action: any): any => {
            const data = action.payload
            const newLicense = data.newLicense
            const priceId = data.priceId
            const orgID = data.orgID

            return from(dataService.updateLicense(priceId, orgID)).pipe(
                mergeMap((response: any) => {
                    return of(
                        updateLicenseActionSuccess(response?.response),
                        // setCurrentTierAction(newLicense),
                        setCurrentStepAction(subscriptionSteps[0]),
                        getLicenses(),
                        showNotification(
                            'subscription.updateLicenseSuccess',
                            'success',
                            ''
                        ),
                        getSubscriptionInfoAction(),
                        hideScreenLoaderAction()
                    )
                }),
                catchError((error): any =>
                    of(
                        updateLicenseActionFailed(error),
                        showNotification(
                            'subscription.updateLicenseError',
                            'error',
                            error.response?.message,
                            error?.status
                        ),
                        hideScreenLoaderAction()
                    )
                )
            )
        })
    )
}

export const getClientSecretEpic: Epic<any, any, RootState> = (
    action$,
    state$
) => {
    return action$.pipe(
        ofType(LicensesTypes.GET_CLIENT_SECRET),
        switchMap((action: any): any => {
            return from(dataService.getClientSecret()).pipe(
                mergeMap((response: any) => {
                    return of(
                        getClientSecretSuccessAction(
                            response?.response?.clientSecret
                        ),
                        hideScreenLoaderAction()
                    )
                }),
                catchError((error): any =>
                    of(
                        getClientSecretFailedAction(error),
                        setActivatedAccount(false),
                        hideScreenLoaderAction()
                    )
                )
            )
        })
    )
}

export const sendPaymentMethodEpic: Epic<any, any, RootState> = (
    action$,
    state$
) => {
    return action$.pipe(
        ofType(LicensesTypes.SEND_PAYMENT_METHOD),
        switchMap((action: any): any => {
            const paymentMethod = action?.payload?.paymentMethod
            return from(dataService.getClientSecret(paymentMethod)).pipe(
                mergeMap((response: any) => {
                    return of(
                        sendPaymentMethodSuccessAction(),
                        getSubscriptionInfoAction(),
                        updateLicenseActionSuccess(),
                        hideScreenLoaderAction()
                    )
                }),
                catchError((error): any =>
                    of(
                        getClientSecretFailedAction(error),
                        hideScreenLoaderAction(),
                        setActivatedAccount(false)
                    )
                )
            )
        })
    )
}

export const getSubscriptionInfoEpic: Epic<any, any, RootState> = (
    action$,
    state$
) => {
    return action$.pipe(
        ofType(LicensesTypes.GET_SUBSCRIPTION_INFO),
        switchMap((action: any): any => {
            return from(dataService.getSubscriptionInfo()).pipe(
                mergeMap((response: any) => {
                    const isIncomplete =
                        response?.response?.subscriptionStatus !== 'inclomplete'
                    noPaymentMethod &&
                        !isIncomplete &&
                        (localStorageService.deleteLocalStorageData('SubR'),
                        localStorageService.deleteLocalStorageData('UpO'))
                    return of(
                        getSubscriptionInfoSuccessAction(response?.response),
                        hideScreenLoaderAction()
                    )
                }),
                catchError((error): any =>
                    of(
                        getSubscriptionInfoFailedAction(),
                        hideScreenLoaderAction(),
                        showNotification(
                            'subscription.getLicenseInfoError',
                            'error',
                            '',
                            error?.status
                        )
                    )
                )
            )
        })
    )
}

export const cancelSubscriptionEpic: Epic<any, any, RootState> = (
    action$,
    state$
) => {
    return action$.pipe(
        ofType(LicensesTypes.CANCEL_SUBSCRIPTION),
        switchMap((action: any): any => {
            return from(dataService.cancelSubscription()).pipe(
                mergeMap((response: any) => {
                    return of(
                        cancelSubscriptionSuccessAction(
                            response?.response?.clientSecret
                        ),
                        hideScreenLoaderAction(),
                        // Just in cloud for now
                        !onPremise && getSubscriptionInfoAction()
                    )
                }),
                catchError((error): any =>
                    of(
                        cancelSubscriptionFailedAction(),
                        // Just in cloud for now
                        !onPremise && getSubscriptionInfoAction(),
                        hideScreenLoaderAction(),
                        showNotification(
                            'subscription.cancelSubscriptionError',
                            'error',
                            '',
                            error?.status
                        )
                    )
                )
            )
        })
    )
}

export const cancelDowngradeEpic: Epic<any, any, RootState> = (
    action$,
    state$
) => {
    return action$.pipe(
        ofType(LicensesTypes.CANCEL_DOWNGRADE),
        switchMap((action: any): any => {
            return from(dataService.cancelDowngradeOrCancelation()).pipe(
                mergeMap((response: any) => {
                    return of(
                        cancelDowngradeSuccessAction(
                            response?.response?.clientSecret
                        ),
                        // Just in cloud for now
                        !onPremise && getSubscriptionInfoAction(),
                        hideScreenLoaderAction(),
                        showNotification(
                            'subscription.cancelDowngradeSuccess',
                            'success',
                            ''
                        )
                    )
                }),
                catchError((error): any =>
                    of(
                        cancelDowngradeFailedAction(),
                        hideScreenLoaderAction(),
                        showNotification(
                            'subscription.cancelDowngradeError',
                            'error',
                            '',
                            error?.status
                        )
                    )
                )
            )
        })
    )
}

export const requestSubscriptionEpic: Epic<any, any, RootState> = (
    action$,
    state$
) => {
    return action$.pipe(
        ofType(LicensesTypes.REQUEST_SUBSCRIPTION),
        switchMap((action: any): any => {
            const data = action?.payload
            const request = {
                type: 'SubscriptionExternalProviderManagement',
                data: data,
            }
            return from(dataService.requestSubscription(request)).pipe(
                mergeMap((response: any) => {
                    // SubR: Submitted Request
                    noPaymentMethod &&
                        localStorageService.setLocalStorageData('SubR', 'Y')
                    return of(requestSubscriptionActionSuccess())
                }),
                catchError((error): any =>
                    of(
                        requestSubscriptionActionFailed(error),
                        showNotification(
                            'subscription.requestSubscritptionError',
                            'error',
                            '',
                            error?.status
                        )
                    )
                )
            )
        })
    )
}
