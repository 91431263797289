import { t } from 'i18next'
import { gaImages } from '../../../assets/globalConstants'

export const popularLicenseType = 'Professional'

export const licensesFeatures = [
    {
        id: 'prod_MGzBzDyVeNERb0',
        id_dev: 'prod_MGaUuNFabgCZCr',
        // id_dev: 'prod_MGzBzDyVeNERb0',
        name: 'Gaas Studio Starter',
        name_prod: 'Gataca Studio Starter',
        name_dev: 'Gaas Studio Starter Test',
        description: 'subscription.descriptionStarter',
        licenseType: 'Starter',
        order: 1,
        features: {
            verificationTemplates: 1,
            activeUsers: 5,
            activeUsersInfo: 'subscription.activeUsersTooltipFree',
            issuanceTemplates: 1,
            issuedCredentials: 5,
            issuedCredentialsInfo: 'subscription.issuedCredentialsTooltipFree',
            dids: 1,
            apiKeys: 1,
            apiKeysInfo: 'subscription.apiKeysInfo',
            notifications: ['Web'],
            stadistics: true,
            nFactor: [],
            didPublicCatalogue: false,
            newSchemaPublicCatalogue: '',
            customQR: '',
            support: '',
            slas: false,
        },
    },
    {
        id: 'prod_MGyG01jd583li0',
        id_dev: 'prod_MDdB3ACkEdBwFM',
        // id_dev: 'prod_MGyG01jd583li0',
        name: 'Gataca Studio Essential',
        name_prod: 'Gataca Studio Essential',
        name_dev: 'Gaas Studio Essential Test',
        description: 'subscription.descriptionEssential',
        licenseType: 'Essential',
        order: 2,
        features: {
            verificationTemplates: 2,
            activeUsers: 1000,
            activeUsersInfo: 'subscription.activeUsersTooltipFree',
            issuanceTemplates: 1,
            issuedCredentials: 10,
            issuedCredentialsInfo: 'subscription.issuedCredentialsTooltipFree',
            dids: 1,
            apiKeys: 2,
            apiKeysInfo: 'subscription.apiKeysInfo',
            notifications: ['Web'],
            stadistics: true,
            nFactor: [
                t('subscription.silentOtp'),
                t('subscription.hardware'),
                t('subscription.biometrics'),
            ],
            didPublicCatalogue: true,
            newSchemaPublicCatalogue: '',
            customQR: '',
            support: t('subscription.ticketing'),
            slas: false,
        },
    },
    {
        id: 'prod_MGz6kA9IqpZSwn',
        id_dev: 'prod_MGg2AGOpV3E9WI',
        // id_dev: 'prod_MGz6kA9IqpZSwn',
        name: 'Gataca Studio Professional',
        name_prod: 'Gataca Studio Professional',
        name_dev: 'Gaas Studio Professional Test',
        description: 'subscription.descriptionProfessional',
        licenseType: 'Professional',
        order: 3,
        features: {
            verificationTemplates: 5,
            activeUsers: 10000,
            activeUsersInfo: 'subscription.activeUsersTooltipFree',
            issuanceTemplates: 2,
            issuedCredentials: 1000,
            issuedCredentialsInfo: 'subscription.issuedCredentialsTooltipFree',
            dids: 10,
            apiKeys: 10,
            apiKeysInfo: 'subscription.apiKeysInfo',
            stadistics: true,
            nFactor: [
                t('subscription.silentOtp'),
                t('subscription.emailOtp'),
                t('subscription.smsOtp'),
                t('subscription.biometrics'),
            ],
            notifications: [t('public.email'), 'Web'],
            didPublicCatalogue: true,
            newSchemaPublicCatalogue: t('subscription.comingSoon'),
            customQR: t('subscription.comingSoon'),
            support: t('subscription.ticketing'),
            slas: false,
        },
    },
    {
        id: 'prod_MGz7OnGxvqnDfa',
        id_dev: 'prod_MmVim6Z2j7KxZL',
        // id_dev: 'prod_MGz7OnGxvqnDfa',
        name: 'Gataca Studio Enterprise',
        name_prod: 'Gataca Studio Enterprise',
        name_dev: 'Gataca Studio Enterprise Test',
        description: 'subscription.descriptionEnterprise',
        licenseType: 'Enterprise',
        order: 4,
        features: {
            verificationTemplates: '∞',
            activeUsers: '∞',
            activeUsersInfo: 'subscription.activeUsersTooltip',
            issuanceTemplates: '∞',
            issuedCredentials: '∞',
            issuedCredentialsInfo: 'subscription.issuedCredentialsTooltip',
            dids: '∞',
            apiKeys: '∞',
            apiKeysInfo: 'subscription.apiKeysInfo',
            stadistics: true,
            nFactor: [
                t('subscription.silentOtp'),
                t('subscription.emailOtp'),
                t('subscription.smsOtp'),
                t('subscription.biometrics'),
            ],
            notifications: [t('public.email'), 'Web'],
            didPublicCatalogue: true,
            support: t('subscription.ticketing'),
            slas: true,
            newSchemaPublicCatalogue: t('subscription.comingSoon'),
            customQR: t('subscription.comingSoon'),
        },
    },
]

export const subscriptionSteps = [
    {
        id: 1,
        label: 'subscription.products',
        order: 1,
    },
    {
        id: 2,
        label: 'subscription.payments',
        order: 2,
    },
]

export const createSubscriptionSteps = [
    {
        id: 1,
        label: 'subscription.products',
        order: 1,
    },
    {
        id: 2,
        label: 'subscription.customerDetails',
        order: 2,
    },
    {
        id: 3,
        label: 'subscription.payments',
        order: 3,
    },
]

export const licensePlanNames = {
    starter: 'subscription.starterPlan',
    essential: 'subscription.essentialPlan',
    professional: 'subscription.professionalPlan',
    enterprise: 'subscription.enterprisePlan',
}

export const licenseTypeNames = {
    starter: 'subscription.starter',
    essential: 'subscription.essential',
    professional: 'subscription.professional',
    enterprise: 'subscription.enterprise',
}

export const cardStatuses = {
    pass: {
        icon: gaImages.checkCircle,
        label: 'subscription.passCard',
        description: 'subscription.passCardInfo',
    },
    fail: {
        icon: gaImages.failCircle,
        label: 'subscription.failedCard',
        description: 'subscription.failedCardInfo',
    },
    unavailable: {
        icon: gaImages.infoCircle,
        label: 'subscription.unavailableCard',
        description: 'subscription.unavailableCardInfo',
    },
    unchecked: {
        icon: gaImages.infoCircle,
        label: 'subscription.unavailableCard',
        description: 'subscription.unavailableCardInfo',
    },
}

export const switchPeriodButtonOptions = [
    { text: 'subscription.payMonthly', value: 'month' },
    { text: 'subscription.yearly', value: 'year' },
]

export const selectPeriodOptions = [
    { text: 'subscription.monthly', value: 'month' },
    { text: 'subscription.yearly', value: 'year' },
]

export const onboardingSwitchPeriodButtonOptions = [
    { text: 'subscription.monthlyUppercase', value: 'month' },
    { text: 'subscription.yearly', value: 'year' },
]

export const licensesToChooseFromWeb = ['starter', 'essential', 'professional']
