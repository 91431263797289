import { t } from 'i18next'

export const listLicenses = [
    {
        button: {
            label: 'subscription.startFreeTrial',
            outsideWeb: true,
            url: '',
        },
        description:
            '30-day trial for companies looking to get started with the SSI basics',
        features: {
            activeUsers: '5',
            apiKeys: '1',
            credentialsInISO28013: false,
            credentialsInW3CFormat: true,
            customGDPRPurposes: false,
            customQR: false,
            didPublicCatalogue: false,
            dids: '1',
            ebsiIntegration: false,
            integrationCustomTrustFrameworks: false,
            integrationCustomTrustInfraestructure: false,
            issuanceTemplates: '1',
            issuedCredentials: '5',
            nFactor: [],
            newSchemaPublicCatalogue: false,
            notifications: ['Web'],
            premierSupport: false,
            sandBoxEnvironment: true,
            slas: false,
            stadistics: true,
            standardSupport: false,
            verificationTemplates: '1',
        },
        monthlyPrice: 12,
        name: 'Starter',
        subPriceMonthLabel: 'subscription.billedAfterFirstMonth',
        subPriceYearLabel: 'subscription.10billedAfterFirstMonth',
        type: 'Starter',
        yearlyPrice: 129,
    },
    {
        button: {
            label: 'subscription.buyEssential',
            outsideWeb: true,
            url: '',
        },
        description: 'For companies looking to scale their SSI implementation',
        features: {
            activeUsers: '1.000',
            apiKeys: '1',
            credentialsInISO28013: false,
            credentialsInW3CFormat: true,
            customGDPRPurposes: false,
            customQR: false,
            didPublicCatalogue: true,
            dids: '2',
            ebsiIntegration: false,
            integrationCustomTrustFrameworks: false,
            integrationCustomTrustInfraestructure: false,
            issuanceTemplates: '1',
            issuedCredentials: '10',
            nFactor: [
                'subscription.silentOtp',
                'subscription.hardware',
                'subscription.biometrics',
            ],
            newSchemaPublicCatalogue: false,
            notifications: ['Web'],
            premierSupport: false,
            sandBoxEnvironment: true,
            slas: false,
            stadistics: true,
            standardSupport: 'subscription.extraChargesMayApply',
            verificationTemplates: '2',
        },
        monthlyPrice: 149,
        name: 'Essential',
        type: 'Essential',
        yearlyPrice: 1.609,
    },
    {
        button: {
            label: 'subscription.buyProfessional',
            outsideWeb: true,
            url: '',
        },
        description:
            'For experienced companies ready for a large scale rollout of SSI',
        features: {
            activeUsers: '10.000',
            apiKeys: '10',
            credentialsInISO28013: false,
            credentialsInW3CFormat: true,
            customGDPRPurposes: true,
            customQR: true,
            didPublicCatalogue: true,
            dids: '10',
            ebsiIntegration: true,
            integrationCustomTrustFrameworks: false,
            integrationCustomTrustInfraestructure: false,
            issuanceTemplates: '2',
            issuedCredentials: '1.000',
            nFactor: [
                'subscription.silentOtp',
                'subscription.emailOtp',
                'subscription.smsOtp',
                'subscription.biometrics',
            ],
            newSchemaPublicCatalogue: true,
            notifications: ['Email', 'Web'],
            premierSupport: 'subscription.extraChargesMayApply',
            sandBoxEnvironment: true,
            slas: false,
            stadistics: true,
            standardSupport: true,
            verificationTemplates: '5',
        },
        monthlyPrice: '1.890',
        name: 'Professional',
        type: 'Professional',
        yearlyPrice: 20.412,
    },
    {
        button: {
            label: 'Contact us',
            outsideWeb: false,
            url: '/company/contact',
        },
        description: 'For companies that have mastered SSI and need extra care',
        features: {
            activeUsers: '∞',
            activeUsersInfo:
                "'An active user is a verified user with an active consent. We charge a fix monthly fee per user, not per login event. Add aditiona Active User for 0,12€ each.'",
            apiKeys: '∞',
            credentialsInISO28013: true,
            credentialsInW3CFormat: true,
            customGDPRPurposes: true,
            customQR: true,
            didPublicCatalogue: true,
            dids: '∞',
            ebsiIntegration: true,
            integrationCustomTrustFrameworks: true,
            integrationCustomTrustInfraestructure: true,
            issuanceTemplates: '∞',
            issuedCredentials: '∞',
            issuedCredentialsInfo:
                'Becoming an issuer in the public catalogue requires a validation process to verify the identity of the issuer and approve its issuance rights. Add aditional Issued VC for 0,10€ each.',
            nFactor: [
                'subscription.silentOtp',
                'subscription.emailOtp',
                'subscription.smsOtp',
                'subscription.biometrics',
            ],
            newSchemaPublicCatalogue: true,
            notifications: ['Email', 'Web'],
            premierSupport: true,
            sandBoxEnvironment: true,
            slas: true,
            stadistics: true,
            standardSupport: true,
            verificationTemplates: '∞',
        },
        monthlyPrice: t('subscription.customPricing'),
        name: 'Enterprise',
        type: 'Enterprise',
        yearlyPrice: t('subscription.customPricing'),
    },
]

export const licensesInfoToggles = {
    activeUser: 'subscription.activeUserInfo',
    didInPublicCatalogue: 'subscription.didInPublicCatalogueInfo',
    ebsiIntegration: '',
    integrationCustomFrameworks: '',
    integrationCustomInfraestructure: '',
    issuanceTemplate: 'subscription.issuanceTemplateInfo',
    issuedCredential: 'subscription.issuedCredentialInfo',
    sandboxEnvironment: '',
    schemaInPublicCatalogue: 'subscription.schemaInPublicCatalogueInfo',
    securityFactorMechanism: 'subscription.securityFactorMechanismInfo',
    verificationTemplate: 'subscription.verificationTemplateInfo',
}
