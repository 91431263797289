import React, { FunctionComponent, SVGProps } from 'react'
import { useTranslation } from 'react-i18next'
import { ImageModel } from '../../../../interfaces/interfaces'
import GaImage from '../../Image/Image'
import style from './lightButton.module.scss'
import cx from 'classnames'
import { ButtonSizes } from '../../../../data/globalVar'

type LightButtonProps = {
    id?: string
    text: string
    SvgIcon?: FunctionComponent<SVGProps<SVGSVGElement>>
    disabled: boolean
    type?: 'button' | 'submit' | 'reset' | undefined
    icon?: ImageModel
    className?: string
    size?: ButtonSizes
    functionality?: ((x?: any) => void) | any
    noBorder?: boolean
}

const LightButton: React.FC<LightButtonProps> = React.memo((props) => {
    const {
        id,
        text,
        SvgIcon,
        type,
        disabled,
        icon,
        className,
        size,
        functionality,
        noBorder,
    } = props
    const { t } = useTranslation()

    const sizeFontClass = {
        LG: 'buttonLG',
        MD: 'buttonMD',
        SM: 'buttonSM',
    }

    const sizeClass = {
        LG: style.large,
        MD: style.medium,
        SM: style.small,
    }
    return (
        <button
            id={id || ''}
            className={`${style.lightButton} ${className} ${
                disabled ? style.lightButtonInActive : style.lightButtonActive
            } ${cx(
                sizeClass[size || 'MD'],
                sizeFontClass[size || 'MD'],
                'neutral1000'
            )} ${noBorder ? style.noBorder : ''}`}
            onClick={functionality}
            disabled={disabled}
            type={type || 'button'}
        >
            {t(text)}
            {SvgIcon ? (
                <SvgIcon
                    className={`${style.lightButton__icon} ${
                        disabled
                            ? style.lightButtonIconInActive
                            : style.lightButtonIconActive
                    }`}
                />
            ) : icon ? (
                <GaImage className={style.lightButton__icon} image={icon} />
            ) : null}
        </button>
    )
})

export default LightButton
