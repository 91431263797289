import moment from 'moment'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { licensesSelectors } from '../../../..'
import { gaImages } from '../../../../../../../assets/globalConstants'
import LightButton from '../../../../../../../Components/atoms/buttons/LightButton/LightButton'
import GaImage from '../../../../../../../Components/atoms/Image/Image'
import LightPanel from '../../../../../../../Components/atoms/panels/LightPanel/LightPanel'
import Description from '../../../../../../../Components/atoms/texts/Description/Description'
import {
    IPriceModel,
    ITierModel,
    StepModel,
} from '../../../../../../../interfaces/interfaces'
import { formatStringDate } from '../../../../../../../utils/formatDataUtil'
import { popularLicenseType } from '../../../../licenses.constants'
import { setCurrentStepAction } from '../../../../licensesActions'
import FeaturesModal from '../../featuresModal/FeaturesModal'
import styles from './selectSubscriptionFirstStep.module.scss'
import cx from 'classnames'
import {
    getIfProductHasTrialDays,
    getTierActivePriceMonthly,
    getTierActivePriceYearly,
    getTierLimitToDisplay,
    getTierLimitsValues,
} from '../../../../../../../utils/licensesUtil'
import GaRadioButton from '../../../../../../../Components/atoms/formFields/radioButton/GaRadioButton'
import GaHoverTooltip from '../../../../../../../Components/molecules/tooltips/GaHoverTooltip/GaHoverTooltip'
import { TooltipPosition } from '../../../../../../../data/globalVar'

type IChangeSubscriptionFirstStepProps = {
    switchPeriodValue: string
    flowSteps: StepModel[]
    currentLicense: ITierModel
    newLicense: ITierModel
    selectedPeriod?: string
    setPeriodChecked: (x?) => void
    setIsNextStepActive: (x?) => void
    setNewLicense: (x?) => void
    getPrice: (x?, y?) => number | undefined
}

const SelectSubscriptionFirstStep: React.FC<
    IChangeSubscriptionFirstStepProps
> = (props) => {
    const {
        switchPeriodValue,
        newLicense,
        flowSteps,
        selectedPeriod,
        getPrice,
        setNewLicense,
        setIsNextStepActive,
        setPeriodChecked,
    } = props
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const subscriptionIsUpdating = useSelector(
        licensesSelectors.updatingSubscriptionLoading
    )
    const currentLicense = useSelector(licensesSelectors.getCurrentProduct)
    const subscriptionInfo = useSelector(licensesSelectors.getSubscriptionInfo)
    const currentSubscriptionPeriod = useSelector(
        licensesSelectors.getCurrentSubscriptionPeriod
    )
    const tiers = useSelector(licensesSelectors.getProviderTiersAndFeatures)

    const [showAllFeatures, setShowAllFeaturesModal] = React.useState(false)
    const showAllFeaturesModal = () => setShowAllFeaturesModal(true)
    const hideAllFeaturesModal = () => setShowAllFeaturesModal(false)

    const currentSubscriptionPeriodIsYear = currentSubscriptionPeriod === 'year'
    const currentIsEqualToNewLicense = newLicense?.id === currentLicense?.id
    const newLicenseProduct = tiers?.find((el) => el.id === newLicense?.id)

    useEffect(() => {
        if (!subscriptionIsUpdating) {
            dispatch(setCurrentStepAction(flowSteps[0]))
        }
    }, [])
    useEffect(() => {
        if (currentLicense && newLicense) {
            setIsNextStepActive(true)
        }
    }, [currentLicense, newLicense])
    const newLicenseDate = formatStringDate(
        subscriptionInfo?.currentPeriodEnd,
        'DD/MM/YYYY'
    )

    useEffect(() => {
        setPeriodChecked(switchPeriodValue)
    }, [currentLicense])

    const availableProducts = () => {
        return tiers?.filter((tier) => {
            return tier?.id !== currentLicense?.id
        })
    }

    const limitToDisplay = (tierLimit?: number | string) => {
        return getTierLimitToDisplay(tierLimit)
    }

    return (
        <div className={styles.changeSubscriptionContainer}>
            <div className={styles.changeSubscriptionContainer__licenses}>
                <LightPanel className={styles.license}>
                    <GaRadioButton
                        id={'period'}
                        index={'0'}
                        name={'period'}
                        value={'month'}
                        checked={true}
                        onChange={() => {
                            setNewLicense(newLicenseProduct)
                        }}
                    />
                    <div>
                        <div className={styles.license__header}>
                            <p
                                className={cx(
                                    'heading6 neutral1000',
                                    styles.license__header__title
                                )}
                            >
                                {newLicenseProduct?.name}
                            </p>
                            {newLicenseProduct?.type === popularLicenseType && (
                                <div
                                    className={
                                        styles.license__header__popularTag
                                    }
                                >
                                    <p>{t('subscription.popular')}</p>
                                </div>
                            )}
                        </div>
                        {newLicenseProduct?.description && (
                            <Description
                                className={styles.license__description}
                                text={newLicenseProduct?.description}
                            />
                        )}
                        <p
                            className={cx(
                                'bodyRegularMD neutral700',
                                styles.license__price
                            )}
                        >
                            {!!getPrice(
                                newLicenseProduct?.prices,
                                switchPeriodValue
                            ) ? (
                                <>
                                    <span
                                        className={cx('heading3 neutral1000')}
                                    >
                                        {getPrice(
                                            newLicenseProduct?.prices,
                                            switchPeriodValue
                                        )}
                                        €
                                    </span>{' '}
                                    &nbsp; /{' '}
                                    {t('subscription.' + switchPeriodValue)}
                                </>
                            ) : (
                                <span className={cx('heading3 neutral1000')}>
                                    {t('subscription.customPricing')}
                                </span>
                            )}
                        </p>
                        <div className={styles.license__features}>
                            <div>
                                <p
                                    className={cx(
                                        'bodyBoldXS neutral700',
                                        styles.license__features__title
                                    )}
                                >
                                    {t('subscription.features')}
                                </p>
                                <ul className={styles.license__features__list}>
                                    <li
                                        className={
                                            styles.license__features__list__item
                                        }
                                    >
                                        <GaImage image={gaImages.check} />{' '}
                                        <span
                                            className={cx(
                                                'bodyBoldMD neutral1000'
                                            )}
                                        >
                                            {limitToDisplay(
                                                getTierLimitsValues(
                                                    newLicenseProduct
                                                )?.verificationTemplates
                                            )}
                                        </span>
                                        <span
                                            className={cx(
                                                'bodyRegularSM neutral1000'
                                            )}
                                        >
                                            {t(
                                                'subscription.verificationTemplates'
                                            )}
                                        </span>
                                    </li>
                                    <li
                                        className={
                                            styles.license__features__list__item
                                        }
                                    >
                                        <GaHoverTooltip
                                            position={TooltipPosition.bottom}
                                            label={
                                                <>
                                                    <GaImage
                                                        image={gaImages.check}
                                                    />
                                                    <span
                                                        className={cx(
                                                            'bodyBoldMD neutral1000',
                                                            styles.featureQuantity
                                                        )}
                                                    >
                                                        {limitToDisplay(
                                                            getTierLimitsValues(
                                                                newLicenseProduct
                                                            )?.activeUsers
                                                        )}
                                                    </span>
                                                    <p
                                                        className={cx(
                                                            'bodyRegularSM neutral1000',
                                                            styles.featureName
                                                        )}
                                                    >
                                                        {t(
                                                            'subscription.activeUsers'
                                                        )}
                                                    </p>
                                                </>
                                            }
                                            info={t(
                                                'subscription.activeUsersTooltipFree'
                                            )}
                                        />
                                    </li>
                                </ul>
                            </div>
                            <div>
                                <p
                                    className={cx(
                                        'bodyBoldXS neutral700',
                                        styles.license__features__title
                                    )}
                                >
                                    {t('subscription.issuanceFeatures')}
                                </p>
                                <ul className={styles.license__features__list}>
                                    <li
                                        className={
                                            styles.license__features__list__item
                                        }
                                    >
                                        <GaImage image={gaImages.check} />{' '}
                                        <span
                                            className={cx(
                                                'bodyBoldMD neutral1000'
                                            )}
                                        >
                                            {limitToDisplay(
                                                getTierLimitsValues(
                                                    newLicenseProduct
                                                )?.issuanceTemplates
                                            )}
                                        </span>
                                        <span
                                            className={cx(
                                                'bodyRegularSM neutral1000'
                                            )}
                                        >
                                            {t(
                                                'subscription.issuanceTemplates'
                                            )}
                                        </span>
                                    </li>
                                    <li
                                        className={
                                            styles.license__features__list__item
                                        }
                                    >
                                        <GaHoverTooltip
                                            position={TooltipPosition.bottom}
                                            label={
                                                <>
                                                    <GaImage
                                                        image={gaImages.check}
                                                    />
                                                    <span
                                                        className={cx(
                                                            'bodyBoldMD neutral1000',
                                                            styles.featureQuantity
                                                        )}
                                                    >
                                                        {limitToDisplay(
                                                            getTierLimitsValues(
                                                                newLicenseProduct
                                                            )?.issuedCredentials
                                                        )}
                                                    </span>
                                                    <p
                                                        className={cx(
                                                            'bodyRegularSM neutral1000',
                                                            styles.featureName
                                                        )}
                                                    >
                                                        {t(
                                                            'subscription.issuedCredentials'
                                                        )}
                                                    </p>
                                                </>
                                            }
                                            info={t(
                                                'subscription.issuedCredentialsTooltipFree'
                                            )}
                                        />
                                    </li>
                                </ul>
                            </div>
                        </div>

                        {/* COMEMNTED because this will be restored in the future
                        <LightButton
                            className={styles.license__button}
                            functionality={showAllFeaturesModal}
                            text={'subscription.seeAllFeatures'}
                            disabled={false}
                        /> */}
                    </div>
                </LightPanel>

                <div className={styles.availableLicenses}>
                    <p
                        className={cx(
                            'heading6 neutral1000',
                            styles.availableLicenses__title
                        )}
                    >
                        {t('subscription.availableSubscriptions')}
                    </p>

                    {availableProducts()?.map((tier, index) => {
                        return (
                            tier !== newLicense && (
                                <LightPanel className={styles.license}>
                                    <GaRadioButton
                                        id={'period'}
                                        index={'' + index}
                                        name={'period'}
                                        value={'month'}
                                        checked={tier === newLicense}
                                        onChange={() => {
                                            setNewLicense(tier)
                                        }}
                                    />
                                    <div>
                                        <div className={styles.license__header}>
                                            <p
                                                className={cx(
                                                    'heading6 neutral1000',
                                                    styles.license__header__title
                                                )}
                                            >
                                                {tier?.name}
                                            </p>
                                            {tier?.type ===
                                                popularLicenseType && (
                                                <div
                                                    className={
                                                        styles.license__header__popularTag
                                                    }
                                                >
                                                    <p>
                                                        {t(
                                                            'subscription.popular'
                                                        )}
                                                    </p>
                                                </div>
                                            )}
                                        </div>
                                        {tier.description && (
                                            <Description
                                                className={
                                                    styles.license__description
                                                }
                                                text={tier.description}
                                            />
                                        )}
                                        <p
                                            className={cx(
                                                'bodyRegularMD neutral700',
                                                styles.license__price
                                            )}
                                        >
                                            <span
                                                className={cx(
                                                    'heading3 neutral1000'
                                                )}
                                            >
                                                {getPrice(
                                                    tier?.prices,
                                                    switchPeriodValue
                                                )
                                                    ? getPrice(
                                                          tier?.prices,
                                                          switchPeriodValue
                                                      ) + '€'
                                                    : 'Custom'}
                                            </span>{' '}
                                            &nbsp; /{' '}
                                            {t(
                                                'subscription.' +
                                                    switchPeriodValue
                                            )}
                                        </p>
                                        <div
                                            className={styles.license__features}
                                        >
                                            <div>
                                                <p
                                                    className={cx(
                                                        'bodyBoldXS neutral700',
                                                        styles.license__features__title
                                                    )}
                                                >
                                                    {t('subscription.features')}
                                                </p>
                                                <ul
                                                    className={
                                                        styles.license__features__list
                                                    }
                                                >
                                                    <li
                                                        className={
                                                            styles.license__features__list__item
                                                        }
                                                    >
                                                        <GaImage
                                                            image={
                                                                gaImages.check
                                                            }
                                                        />{' '}
                                                        <span
                                                            className={cx(
                                                                'bodyBoldMD neutral1000'
                                                            )}
                                                        >
                                                            {limitToDisplay(
                                                                getTierLimitsValues(
                                                                    tier
                                                                )
                                                                    ?.verificationTemplates
                                                            )}
                                                        </span>
                                                        <span
                                                            className={cx(
                                                                'bodyRegularSM neutral1000'
                                                            )}
                                                        >
                                                            {t(
                                                                'subscription.verificationTemplates'
                                                            )}
                                                        </span>
                                                    </li>
                                                    <li
                                                        className={
                                                            styles.license__features__list__item
                                                        }
                                                    >
                                                        <GaHoverTooltip
                                                            position={
                                                                TooltipPosition.bottom
                                                            }
                                                            label={
                                                                <>
                                                                    <GaImage
                                                                        image={
                                                                            gaImages.check
                                                                        }
                                                                    />
                                                                    <span
                                                                        className={cx(
                                                                            'bodyBoldMD neutral1000',
                                                                            styles.featureQuantity
                                                                        )}
                                                                    >
                                                                        {limitToDisplay(
                                                                            getTierLimitsValues(
                                                                                tier
                                                                            )
                                                                                ?.activeUsers
                                                                        )}
                                                                    </span>
                                                                    <p
                                                                        className={cx(
                                                                            'bodyRegularSM neutral1000',
                                                                            styles.featureName
                                                                        )}
                                                                    >
                                                                        {t(
                                                                            'subscription.activeUsers'
                                                                        )}
                                                                    </p>
                                                                </>
                                                            }
                                                            info={t(
                                                                'subscription.activeUsersTooltipFree'
                                                            )}
                                                        />
                                                    </li>
                                                </ul>
                                            </div>
                                            <div>
                                                <p
                                                    className={cx(
                                                        'bodyBoldXS neutral700',
                                                        styles.license__features__title
                                                    )}
                                                >
                                                    {t(
                                                        'subscription.issuanceFeatures'
                                                    )}
                                                </p>
                                                <ul
                                                    className={
                                                        styles.license__features__list
                                                    }
                                                >
                                                    <li
                                                        className={
                                                            styles.license__features__list__item
                                                        }
                                                    >
                                                        <GaImage
                                                            image={
                                                                gaImages.check
                                                            }
                                                        />{' '}
                                                        <span
                                                            className={cx(
                                                                'bodyBoldMD neutral1000'
                                                            )}
                                                        >
                                                            {limitToDisplay(
                                                                getTierLimitsValues(
                                                                    tier
                                                                )
                                                                    ?.issuanceTemplates
                                                            )}
                                                        </span>
                                                        <span
                                                            className={cx(
                                                                'bodyRegularSM neutral1000'
                                                            )}
                                                        >
                                                            {t(
                                                                'subscription.issuanceTemplates'
                                                            )}
                                                        </span>
                                                    </li>
                                                    <li
                                                        className={
                                                            styles.license__features__list__item
                                                        }
                                                    >
                                                        <GaHoverTooltip
                                                            position={
                                                                TooltipPosition.bottom
                                                            }
                                                            label={
                                                                <>
                                                                    <GaImage
                                                                        image={
                                                                            gaImages.check
                                                                        }
                                                                    />
                                                                    <span
                                                                        className={cx(
                                                                            'bodyBoldMD neutral1000',
                                                                            styles.featureQuantity
                                                                        )}
                                                                    >
                                                                        {limitToDisplay(
                                                                            getTierLimitsValues(
                                                                                tier
                                                                            )
                                                                                ?.issuedCredentials
                                                                        )}
                                                                    </span>
                                                                    <p
                                                                        className={cx(
                                                                            'bodyRegularSM neutral1000',
                                                                            styles.featureName
                                                                        )}
                                                                    >
                                                                        {t(
                                                                            'subscription.issuedCredentials'
                                                                        )}
                                                                    </p>
                                                                </>
                                                            }
                                                            info={t(
                                                                'subscription.issuedCredentialsTooltipFree'
                                                            )}
                                                        />
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        {/* <LightButton
                                            className={styles.license__button}
                                            functionality={showAllFeaturesModal}
                                            text={'subscription.seeAllFeatures'}
                                            disabled={false}
                                        /> */}
                                    </div>
                                </LightPanel>
                            )
                        )
                    })}

                    {!!currentLicense?.id &&
                    !getIfProductHasTrialDays(
                        currentLicense,
                        selectedPeriod
                    ) ? (
                        <LightPanel className={styles.license}>
                            <GaRadioButton
                                id={'period'}
                                index={'0'}
                                name={'period'}
                                value={'month'}
                                onChange={() => {
                                    setNewLicense(currentLicense)
                                }}
                                checked={currentIsEqualToNewLicense}
                            />
                            <div>
                                <div className={styles.license__header}>
                                    <p
                                        className={cx(
                                            'bodyBoldXS neutral700',
                                            styles.license__features__title
                                        )}
                                    >
                                        {currentLicense?.name}
                                    </p>
                                    {!!currentLicense?.isPopular && (
                                        <div
                                            className={
                                                styles.license__header__popularTag
                                            }
                                        >
                                            <p>{t('subscription.popular')}</p>
                                        </div>
                                    )}
                                </div>
                                {currentLicense?.description && (
                                    <Description
                                        className={styles.license__description}
                                        text={currentLicense?.description}
                                    />
                                )}
                                <p className={styles.license__price}>
                                    <span>
                                        {getPrice(
                                            currentLicense?.prices,
                                            currentSubscriptionPeriod ===
                                                'month'
                                                ? 'year'
                                                : 'month'
                                        )}
                                        €
                                    </span>{' '}
                                    &nbsp; /{' '}
                                    {t(
                                        'subscription.' +
                                            (currentSubscriptionPeriodIsYear
                                                ? 'month'
                                                : 'year')
                                    )}
                                </p>
                                <div className={styles.license__features}>
                                    <div>
                                        <p
                                            className={cx(
                                                'bodyBoldXS neutral700',
                                                styles.license__features__title
                                            )}
                                        >
                                            {t('subscription.features')}
                                        </p>
                                        <ul
                                            className={
                                                styles.license__features__list
                                            }
                                        >
                                            <li
                                                className={
                                                    styles.license__features__list__item
                                                }
                                            >
                                                <GaImage
                                                    image={gaImages.check}
                                                />{' '}
                                                <span
                                                    className={cx(
                                                        'bodyRegularSM neutral1000'
                                                    )}
                                                >
                                                    {
                                                        getTierLimitsValues(
                                                            currentLicense
                                                        )?.verificationTemplates
                                                    }
                                                </span>
                                                {t(
                                                    'subscription.verificationTemplates'
                                                )}
                                            </li>
                                            <li
                                                className={
                                                    styles.license__features__list__item
                                                }
                                            >
                                                <GaHoverTooltip
                                                    position={
                                                        TooltipPosition.bottom
                                                    }
                                                    label={
                                                        <>
                                                            <GaImage
                                                                image={
                                                                    gaImages.check
                                                                }
                                                            />

                                                            <span
                                                                className={cx(
                                                                    'bodyBoldMD neutral1000',
                                                                    styles.featureQuantity
                                                                )}
                                                            >
                                                                {
                                                                    getTierLimitsValues(
                                                                        currentLicense
                                                                    )
                                                                        ?.activeUsers
                                                                }
                                                            </span>
                                                            <p
                                                                className={cx(
                                                                    'bodyRegularSM neutral1000',
                                                                    styles.featureName
                                                                )}
                                                            >
                                                                {t(
                                                                    'subscription.activeUsers'
                                                                )}
                                                            </p>
                                                        </>
                                                    }
                                                    info={t(
                                                        'subscription.activeUsersTooltipFree'
                                                    )}
                                                />
                                            </li>
                                        </ul>
                                    </div>
                                    <div>
                                        <p
                                            className={cx(
                                                'bodyBoldXS neutral700',
                                                styles.license__features__title
                                            )}
                                        >
                                            {t('subscription.issuanceFeatures')}
                                        </p>
                                        <ul
                                            className={
                                                styles.license__features__list
                                            }
                                        >
                                            <li
                                                className={
                                                    styles.license__features__list__item
                                                }
                                            >
                                                <GaImage
                                                    image={gaImages.check}
                                                />{' '}
                                                <span
                                                    className={cx(
                                                        'bodyRegularSM neutral1000'
                                                    )}
                                                >
                                                    {
                                                        getTierLimitsValues(
                                                            currentLicense
                                                        )?.issuanceTemplates
                                                    }
                                                </span>
                                                <span
                                                    className={cx(
                                                        'bodyRegularSM neutral1000',
                                                        styles.featureName
                                                    )}
                                                >
                                                    {t(
                                                        'subscription.issuanceTemplates'
                                                    )}
                                                </span>
                                            </li>
                                            <li
                                                className={
                                                    styles.license__features__list__item
                                                }
                                            >
                                                <GaHoverTooltip
                                                    position={
                                                        TooltipPosition.bottom
                                                    }
                                                    label={
                                                        <>
                                                            <GaImage
                                                                image={
                                                                    gaImages.check
                                                                }
                                                            />
                                                            <span
                                                                className={cx(
                                                                    'bodyBoldMD neutral1000',
                                                                    styles.featureQuantity
                                                                )}
                                                            >
                                                                {
                                                                    getTierLimitsValues(
                                                                        currentLicense
                                                                    )
                                                                        ?.issuedCredentials
                                                                }
                                                            </span>
                                                            <p
                                                                className={cx(
                                                                    'bodyRegularSM neutral1000',
                                                                    styles.featureName
                                                                )}
                                                            >
                                                                {t(
                                                                    'subscription.issuedCredentials'
                                                                )}
                                                            </p>
                                                        </>
                                                    }
                                                    info={t(
                                                        'subscription.issuedCredentialsTooltipFree'
                                                    )}
                                                />
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                {/* <LightButton
                                    className={styles.license__button}
                                    functionality={showAllFeaturesModal}
                                    text={'subscription.seeAllFeatures'}
                                    disabled={false}
                                /> */}
                            </div>
                        </LightPanel>
                    ) : null}
                </div>
            </div>
            {showAllFeatures ? (
                <FeaturesModal
                    show={!!showAllFeatures}
                    switchPeriodValue={switchPeriodValue}
                    hideModal={hideAllFeaturesModal}
                    setPeriodChecked={setPeriodChecked}
                />
            ) : null}
        </div>
    )
}

SelectSubscriptionFirstStep.defaultProps = {}

export default SelectSubscriptionFirstStep
