import cx from 'classnames'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import LightPanel from '../../../../../Components/atoms/panels/LightPanel/LightPanel'
import Slider from '../../../../../Components/slider/Slider'
import { onPremise } from '../../../../../data/globalVar'
import {
    ILicenseItem,
    ISubscriptionInfo,
    ITierModel,
    IUsedFeatures,
} from '../../../../../interfaces/interfaces'
import { getTierLimitsValues } from '../../../../../utils/licensesUtil'
import { hideScreenLoaderAction } from '../../../../common/Loader/loaderActions'
import { licensesSelectors } from '../../../../common/licenses'
import {
    getTiersAction,
    setCurrentTierAction,
    setExcededLimitsAction,
} from '../../../../common/licenses/licensesActions'
import LimitsStats from '../../../../common/licenses/views/limitsStats/LimitsStats'
import styles from './limits.module.scss'

interface InfoLicenseProps {
    userLicense: ILicenseItem[] | undefined
    tiers: any
    currentLicense: ITierModel
    subscriptionInfo: ISubscriptionInfo
    subscriptionInfoIsLoading: boolean
    subscriptionIsUpdating: boolean
    statisticsIsLoading: boolean
    subscriptionIsBeingCancelled: boolean
    downgradeIsBeingCancelled: boolean
    loaderScreenActive: boolean
    currentSubscriptionPeriod: string | undefined
    currentLicenseType: ITierModel
    newLicenseDate?: string
    currentSubscriptionPeriodIsYear: boolean
    usedFeatures: IUsedFeatures | undefined
    tiersAreLoading: boolean
    licenseIsIncomplete?: boolean
    statisticHasFailed?: boolean
    trialLicenseIsIncomplete?: boolean
    isOnHome?: boolean
}

const Limits: React.FC<InfoLicenseProps> = (props) => {
    const {
        userLicense,
        tiers,
        currentLicense,
        subscriptionInfo,
        subscriptionInfoIsLoading,
        subscriptionIsUpdating,
        statisticsIsLoading,
        subscriptionIsBeingCancelled,
        downgradeIsBeingCancelled,
        loaderScreenActive,
        currentSubscriptionPeriod,
        currentLicenseType,
        newLicenseDate,
        currentSubscriptionPeriodIsYear,
        usedFeatures,
        tiersAreLoading,
        licenseIsIncomplete,
        statisticHasFailed,
        trialLicenseIsIncomplete,
        isOnHome,
    } = props
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const isNaturalNumber = (item) =>
        item !== undefined &&
        item !== null &&
        typeof item === 'number' &&
        !Number.isNaN(item) &&
        item >= 0
    const excededLimitsState = useSelector(licensesSelectors.excededLimits)

    useEffect(() => {
        if (
            loaderScreenActive &&
            !subscriptionInfoIsLoading &&
            !subscriptionIsBeingCancelled &&
            !downgradeIsBeingCancelled &&
            !subscriptionIsUpdating
        ) {
            dispatch(hideScreenLoaderAction())
        }
        if (tiers && !currentLicense?.id) {
            if (subscriptionInfo?.currentTierId?.length) {
                const currentLicense = tiers?.find((el) => {
                    return (
                        el?.id?.toLowerCase() ===
                        subscriptionInfo?.currentTierId?.toLowerCase()
                    )
                })
                dispatch(setCurrentTierAction(currentLicense))
            } else if (userLicense && userLicense[0]?.licenseType) {
                const currentLicense = tiers?.find((el) => {
                    const lowerCaseName = el?.name?.toLowerCase()
                    const lowerCaseLicenseType = el?.name?.toLowerCase()
                    return !!lowerCaseName?.includes(lowerCaseLicenseType)
                })
                dispatch(setCurrentTierAction(currentLicense))
            }
        }
    }, [tiers?.length])

    useEffect(() => {
        if (!tiers?.length && !tiersAreLoading) {
            // Just in cloud for now
            !onPremise && dispatch(getTiersAction())
        }
    }, [currentLicense?.id])

    useEffect(() => {
        const excededLimit = items?.filter(
            (el) => isNaturalNumber(el.total) && el.total < el.quantity
        )
        const excededLimitLabels = excededLimit?.map((el) => el.item)
        if (excededLimitLabels?.length && !excededLimitsState?.length) {
            dispatch(setExcededLimitsAction(excededLimitLabels))
        }
    }, [usedFeatures, currentLicense?.id])

    const items = [
        {
            id: 'first',
            quantity: usedFeatures?.verifierTemplates,
            total: getTierLimitsValues(currentLicense)?.verificationTemplates,
            label: 'subscription.verificationTemplates',
            unlimitedLabel: 'subscription.unlimitedFem',
            item: 'subscription.limitedFeatures.verificationTemplates',
        },
        {
            quantity: usedFeatures?.issuanceTemplates,
            total: getTierLimitsValues(currentLicense)?.issuanceTemplates,
            label: 'subscription.issuanceTemplates',
            unlimitedLabel: 'subscription.unlimitedFem',
            item: 'subscription.limitedFeatures.issuanceTemplates',
        },
        {
            quantity: usedFeatures?.dids,
            total: getTierLimitsValues(currentLicense)?.dids,
            label: 'subscription.dids',
            unlimitedLabel: 'subscription.unlimitedMasc',
            item: 'subscription.limitedFeatures.dids',
        },
        {
            id: 'last',
            quantity: usedFeatures?.apiKeys,
            total: getTierLimitsValues(currentLicense)?.apiKeys,
            label: 'subscription.apiKeys',
            unlimitedLabel: 'subscription.unlimitedFem',
            item: 'subscription.limitedFeatures.apiKeys',
        },
    ]

    return (
        <div className={styles.subscriptionPanelContainer}>
            {(currentLicenseType?.id || trialLicenseIsIncomplete) &&
            usedFeatures ? (
                <LightPanel className={styles.currentSubscriptionPanel}>
                    <div className={styles.currentSubscriptionPanel__info}>
                        <p className={`${cx('heading6 neutral1000')}`}>
                            {t('subscription.currentPlan')}:{' '}
                            <span
                                className={`${styles.description} ${cx(
                                    'primary700'
                                )}`}
                            >
                                {!trialLicenseIsIncomplete
                                    ? currentLicenseType?.name +
                                      ' ' +
                                      t(
                                          currentSubscriptionPeriod === 'year'
                                              ? 'subscription.yearly'
                                              : 'subscription.monthly'
                                      )
                                    : t('subscription.freeTrial')}
                            </span>
                        </p>

                        {subscriptionInfo?.subscriptionStatus === 'active' ? (
                            currentSubscriptionPeriodIsYear ? (
                                <p
                                    className={`${styles.licenseActive} ${cx(
                                        'buttonSM'
                                    )}`}
                                >
                                    {' '}
                                    {t('subscription.active')}
                                    {' ('}
                                    {t('subscription.until', {
                                        date: newLicenseDate,
                                    })}
                                    {')'}
                                </p>
                            ) : (
                                <p
                                    className={`${styles.licenseActive} ${cx(
                                        'buttonSM'
                                    )}`}
                                >
                                    {t('subscription.active')}{' '}
                                </p>
                            )
                        ) : null}

                        {trialLicenseIsIncomplete || !!licenseIsIncomplete ? (
                            <p
                                className={`${styles.licensePending} ${cx(
                                    'buttonSM'
                                )}`}
                            >
                                {t('subscription.pending')}
                            </p>
                        ) : null}

                        {subscriptionInfo?.subscriptionStatus === 'past_due' ? (
                            <p
                                className={`${styles.licenseInactive} ${cx(
                                    'buttonSM'
                                )}`}
                            >
                                {t('subscription.pastDue')}
                            </p>
                        ) : null}

                        {subscriptionInfo?.subscriptionStatus === 'unpaid' ||
                        subscriptionInfo?.subscriptionStatus === 'canceled' ? (
                            <p
                                className={`${styles.licenseInactive} ${cx(
                                    'buttonSM'
                                )}`}
                            >
                                {t('subscription.pastDue')}
                            </p>
                        ) : null}
                        {subscriptionInfo?.subscriptionStatus === 'canceled' ||
                        subscriptionInfo?.subscriptionStatus === 'cancelled' ? (
                            <p
                                className={`${styles.licenseCancelled} ${cx(
                                    'buttonSM'
                                )}`}
                            >
                                {t('subscription.canceled')}
                            </p>
                        ) : null}
                    </div>
                    {usedFeatures && (
                        <>
                            <div className={styles.limits}>
                                <LimitsStats
                                    id="firstLimits"
                                    items={items}
                                    isOnHome={isOnHome}
                                    errorGettingItems={statisticHasFailed}
                                />
                            </div>
                        </>
                    )}
                </LightPanel>
            ) : (
                ((currentLicense?.id &&
                    !subscriptionInfo?.currentTierId?.length) ||
                    trialLicenseIsIncomplete) &&
                !subscriptionInfoIsLoading && (
                    <LightPanel className={styles.currentSubscriptionPanel}>
                        <div>
                            {' '}
                            <p className={`${cx('heading6 neutral1000')}`}>
                                {t('subscription.currentPlan')}:{' '}
                                <span className={`${cx('primary700')}`}>
                                    {' '}
                                    {!trialLicenseIsIncomplete
                                        ? currentLicense?.name
                                        : t('subscription.freeTrial')}
                                </span>
                            </p>{' '}
                        </div>
                        {usedFeatures && (
                            <>
                                <div className={styles.limits}>
                                    <LimitsStats
                                        id="secondLimits"
                                        items={items}
                                        isOnHome={isOnHome}
                                        errorGettingItems={statisticHasFailed}
                                    />
                                </div>
                            </>
                        )}
                    </LightPanel>
                )
            )}
        </div>
    )
}

export default Limits
