import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { didsSelectors } from '../../../studio/Dids'
import {
    IDIDModel,
    IEWCredentialsPaginatedResponseModel,
} from '../../../../interfaces/interfaces'
import { sessionSelectors } from '../../../common/session'
import {
    getIsActiveSession,
    userHasScopes,
} from '../../../../utils/permissionsUtil'
import styles from './requestCredentials.module.scss'
import { didsScopes } from '../../../studio/Dids/views/didList/didList.constants'
import { getCatalog } from '../../../common/catalog/catalogActions'
import { getDids, setEWAccountDidAction } from '../../../studio/Dids/didActions'
import { getStatistics } from '../../../common/Statistics/statisticsActions'
import DidsSelector from '../../../../Components/molecules/didsSelector/DidsSelector'
import { statsSelectors } from '../../../common/Statistics'
import { licensesSelectors } from '../../../common/licenses'
import { errorPanelReachedFeatures } from '../../../common/PanelScafolding/private/panelScafoldingPrivateConstants'
import { getTierLimitsValues } from '../../../../utils/licensesUtil'
import CardListSkeleton from '../../../../Components/molecules/skeletons/cardListSkeleton/CardListSkeleton'
import CredentialCard, {
    ICredentialCardProps,
} from '../components/credentialCard/CredentialCard'
import NoCredentialsPanel from '../components/noCredentialsPanel/NoCredentialsPanel'
import ImportCredentialCard from '../components/importCredentialCard/ImportCredentialCard'
import { credentialsScopes } from '../../../studio/credentials/views/credentialsList/credentialsList.constants'
import { requestCredentialsSelectors } from '../../../../store/requestCredentials'
import {
    deleteCredential,
    getCredentials,
    importCredential,
} from '../../../../store/requestCredentials/requestCredentialsActions'
import CredentialCardDetail from '../components/credentialCardDetail/CredentialCardDetail'
import { catalogSelectors } from '../../../common/catalog'
import { getFormattedCreds } from '../utils/formatCredUtil'
import DarkButton from '../../../../Components/atoms/buttons/DarkButton/DarkButton'
import {
    propertiesToFuzzySearchCredentials,
    scanRequestSteps,
} from '../data/requestCredentials.constants'
import { clearModalState } from '../../../../store/scanModal/scanModalActions'
import { ActionStatusTypes } from '../../../../assets/globalConstants'
import { readCredLDPOrJWTFromTextFile } from '../../../../utils/credentialsUtils'
import SearchableListHeader from '../../../../Components/molecules/headers/SearchableListHeader/SearchableListHeader'
import { showScreenLoaderAction } from '../../../common/Loader/loaderActions'

type ISearchState = {
    searched: string
    searchedResult: any
}

export const RequestCredentials: React.FC = () => {
    const dispatch = useDispatch()

    // Selectors
    const isLoading = useSelector(didsSelectors.isLoading)
    const didConfigs = useSelector(didsSelectors.getEWDids)
    const EWSelectedDid = useSelector(didsSelectors.getEWDid)
    const didsSetup = useSelector(didsSelectors.getDids)
    const availableScopes = useSelector(sessionSelectors?.getAllowedScopes)
    const excededLimitsState = useSelector(licensesSelectors.excededLimits)
    const usedFeatures = useSelector(statsSelectors.getUsedFeaturesNumber)
    const statisticsIsLoading = useSelector(statsSelectors.getStatisticsLoading)
    const currentLicense = useSelector(licensesSelectors.getCurrentProduct)
    const credStatusLoading = useSelector(requestCredentialsSelectors.isLoading)
    const catalogGroups = useSelector(catalogSelectors.getCatalogGroups)
    const catalogIssuers = useSelector(catalogSelectors.getCatalogAuthority)
    const catalogTypes = useSelector(catalogSelectors.getCatalogTypes)
    const loadingCatalog = useSelector(catalogSelectors.getLoadingStatus)
    const credentialsData = useSelector(
        requestCredentialsSelectors.getFormattedCreds
    ) as IEWCredentialsPaginatedResponseModel
    const originalCredentialsData = useSelector(
        requestCredentialsSelectors.getCreds
    ) as IEWCredentialsPaginatedResponseModel
    const getDeleteCredStatus = useSelector(
        requestCredentialsSelectors.getDeleteCredStatus
    )
    const getImportCredStatus = useSelector(
        requestCredentialsSelectors.getImportCredStatus
    )
    const getImportedCred = useSelector(
        requestCredentialsSelectors.getImportedCredential
    )

    // Functions
    const deleteCred = () => {
        if (EWSelectedDid && selectedCredential?.id) {
            dispatch(showScreenLoaderAction()),
                dispatch(
                    deleteCredential(
                        EWSelectedDid,
                        selectedCredential?.id
                        /* TODO: Uncomment if search can be done in backend. BE Pagination not compatible with FE Search */
                        // pageIndex
                        // defaultPageSize
                    )
                )
        }
    }

    const scrollToTop = () => {
        let rootContainer = document
            ? document?.getElementById('root')
            : undefined
        rootContainer?.scrollTo({ top: 0 })
    }

    const selectDid = (e) => dispatch(setEWAccountDidAction(e?.target?.value))

    const selectFile = (e) => {
        if (!e.target.files || e.target.files.length === 0) {
            setFile(undefined)
            return
        }

        const textType = 'text/plain'
        const file = e.target?.files?.length && e.target?.files[0]

        if (file?.type?.match(textType) && EWSelectedDid) {
            const reader = new FileReader()
            reader.onload = function (e) {
                const dataToImport = readCredLDPOrJWTFromTextFile(e)

                dispatch(
                    importCredential(
                        EWSelectedDid,
                        dataToImport
                        /* TODO: Uncomment if search can be done in backend. BE Pagination not compatible with FE Search */
                        // defaultPageSize
                    )
                )
            }

            reader.readAsText(file)
        }

        setFile(file)
    }

    /* TODO: Uncomment if search can be done in backend. BE Pagination not compatible with FE Search */
    // const handlePageChange = (page) => {
    //     dispatch(
    //         getCredentials(
    //             EWSelectedDid || ''
    //             page,
    //             defaultPageSize
    //         )
    //     )
    //     setPageIndex(page)
    //     scrollToTop()
    // }

    const getIfCredIsRecentlyImported = (index: number) => {
        //@ts-ignore
        const importedVCID = getImportedCred?.vc?.id

        const ordiginalCredVCID = originalCredentialsData[index]?.vc?.id

        return (
            JSON.stringify(getImportedCred?.vc) ===
                JSON.stringify(originalCredentialsData[index]?.vc) ||
            (importedVCID && importedVCID === ordiginalCredVCID)
        )
    }

    // States and Consts

    /* TODO: Uncomment if search can be done in backend. BE Pagination not compatible with FE Search */
    // const [pageIndex, setPageIndex] = useState(1)
    // const [totalItems, setTotalItems] = useState(credentialsData?.total || 0)

    const [creds, setCredentials] = useState<
        ICredentialCardProps[] | undefined
    >()

    /* TODO: Uncomment if search can be done in backend. BE Pagination not compatible with FE Search */
    // const [paginatedCreds, setPaginatedCredentials] = useState<
    //     ICredentialCardProps[] | undefined
    // >()

    const hasReadCredsScope = !!(
        availableScopes &&
        userHasScopes(credentialsScopes?.read, availableScopes)
    )

    const hasReadDidsScope = !!(
        availableScopes && userHasScopes(didsScopes?.read, availableScopes)
    )
    const hasCreateDidsScope = !!(
        availableScopes && userHasScopes(didsScopes?.create, availableScopes)
    )
    const showDidsSelector =
        (didConfigs?.length && !!didConfigs[0]) || isLoading

    const limitsAreExceded =
        excededLimitsState?.length &&
        excededLimitsState?.includes(errorPanelReachedFeatures.dids)

    const limitsAreReached =
        usedFeatures?.dids === getTierLimitsValues(currentLicense)?.dids

    const canUserCreateDIDs =
        !!hasCreateDidsScope && !limitsAreReached && !limitsAreExceded
    const initialListState = {
        searched: '',
        searchedResult: creds || '',
        /* TODO: Uncomment if search can be done in backend. BE Pagination not compatible with FE Search */
        // searchedResult: paginatedCreds || '',
    }

    /* TODO: Uncomment if search can be done in backend. BE Pagination not compatible with FE Search */
    // const paginationData = {
    //     currentPage: pageIndex,
    //     totalPages: getTotalPageItems(totalItems || 0, defaultPageSize) || 0,
    //     onChange: handlePageChange,
    //     totalItems: totalItems || 0,
    //     pageSize: defaultPageSize,
    //     page: pageIndex,
    // }
    // const showPagination =
    //     !!paginationData?.totalItems &&
    //     !!paginationData.pageSize &&
    //     paginationData?.totalItems >= paginationData.pageSize &&
    //     !!credentialsData?.items?.length

    const uploadingFile = getImportCredStatus === ActionStatusTypes.loading
    // TODO: Update scopes if backend updates them
    const canRequest = true

    const [dids, setDids] = useState<IDIDModel[]>(didConfigs)
    const [selectedValue, setSelectedValue] = useState(EWSelectedDid)
    const [loading, setLoading] = useState(isLoading)
    const [file, setFile] = useState<any>()
    const [searchState, setSearchState] =
        useState<ISearchState>(initialListState)
    const [showScanModal, setShowScanModal] = React.useState(false)
    const [scanCurrentStep, setScanCurrentStep] = React.useState(
        scanRequestSteps[0]
    )
    const [selectedCredential, setSelectedCredential] = useState<
        ICredentialCardProps | undefined
    >()

    const didAlias = dids?.find((did) => did?.DidId === selectedValue)?.alias

    // Effects
    useEffect(() => {
        if (hasReadDidsScope) {
            dispatch(getDids())
        }
        if (hasReadCredsScope) {
            dispatch(getCatalog()),
                EWSelectedDid &&
                    dispatch(
                        getCredentials(
                            EWSelectedDid
                            /* TODO: Uncomment if search can be done in backend. BE Pagination not compatible with FE Search */
                            // 1,
                            // defaultPageSize
                        )
                    )
        }
        // This will be used in the future to know if more dids can be created or if organization has reached the limit
        if (!statisticsIsLoading && getIsActiveSession()) {
            dispatch(getStatistics())
        }
    }, [])

    useEffect(() => {
        EWSelectedDid &&
            dispatch(
                getCredentials(
                    EWSelectedDid
                    /* TODO: Uncomment if search can be done in backend. BE Pagination not compatible with FE Search */
                    // 1,
                    // defaultPageSize
                )
            )
        setLoading(true)
        setTimeout(() => {
            setSelectedValue(EWSelectedDid)
            setLoading(false)
        }, 1000)
    }, [EWSelectedDid])
    useEffect(() => {}, [didsSetup, dids])
    useEffect(() => {}, [selectedCredential, getImportedCred])

    useEffect(() => {
        if (
            getDeleteCredStatus === ActionStatusTypes.failed ||
            getDeleteCredStatus === ActionStatusTypes.success
        ) {
            scrollToTop()
        }
    }, [getDeleteCredStatus])

    useEffect(() => {
        if (
            getImportCredStatus === ActionStatusTypes.failed ||
            getImportCredStatus === ActionStatusTypes.success
        ) {
            scrollToTop()
        }
    }, [getImportCredStatus])

    useEffect(() => {
        !dids?.length && setDids(didConfigs)
    }, [didConfigs])

    useEffect(() => {
        /* TODO: Uncomment if search can be done in backend. BE Pagination not compatible with FE Search */
        // setPaginatedCredentials(paginate(creds, defaultPageSize, pageIndex))
        setSearchState(initialListState)
    }, [creds])

    useEffect(() => {
        setCredentials(
            credentialsData?.items?.length
                ? getFormattedCreds(
                      credentialsData?.items,
                      catalogGroups,
                      catalogIssuers,
                      catalogTypes
                  )
                : undefined
        )
    }, [credentialsData])

    /* TODO: Uncomment if search can be done in backend. BE Pagination not compatible with FE Search */
    // useEffect(() => {
    //     if (!!creds?.length) {
    //         setSearchState(initialListState)
    //     }
    // }, [paginatedCreds])

    const handleSearchedChange = (items) => {
        if (!!items) {
            setSearchState({
                ...searchState,
                searchedResult: items,
                /* TODO: Uncomment if search can be done in backend. BE Pagination not compatible with FE Search */
                // searchedResult: paginate(items, defaultPageSize, 1),
            })
            /* TODO: Uncomment if search can be done in backend. BE Pagination not compatible with FE Search */
            // setTotalItems(items.length)
        }
    }

    return (
        <div className={styles.requestCredentials}>
            <header className={styles.requestCredentials__header}>
                {showDidsSelector ? (
                    <DidsSelector
                        options={didConfigs}
                        value={selectedValue}
                        onChange={selectDid}
                        canCreate={canUserCreateDIDs}
                        isLoading={loading}
                    />
                ) : null}
                <DarkButton
                    text={'ew.modalScan.request'}
                    disabled={!canRequest}
                    functionality={() => setShowScanModal(true)}
                />
            </header>
            {!isLoading &&
            !credStatusLoading &&
            loadingCatalog?.status !== 'active' ? (
                <SearchableListHeader
                    title={''}
                    isLoading={
                        isLoading ||
                        credStatusLoading ||
                        loadingCatalog?.status === 'active'
                    }
                    className={styles.searchBar}
                    options={creds}
                    propertiesToFuzzySearch={propertiesToFuzzySearchCredentials}
                    handleSearchedChange={handleSearchedChange}
                />
            ) : (
                <CardListSkeleton
                    cardsNumber={1}
                    className={styles.searchBarSkeleton}
                />
            )}

            {!credStatusLoading &&
            loadingCatalog?.status !== 'active' &&
            EWSelectedDid ? (
                credentialsData?.items?.length ? (
                    <div className={styles.credentialsContainer}>
                        <ImportCredentialCard
                            loading={uploadingFile}
                            setFile={selectFile}
                            fileName={file?.name}
                        />
                        {credentialsData?.items?.length &&
                        creds?.length &&
                        searchState?.searchedResult?.length
                            ? searchState?.searchedResult?.map(
                                  (cred, index) => {
                                      return (
                                          <CredentialCard
                                              key={'cred__' + index}
                                              {...cred}
                                              isNew={getIfCredIsRecentlyImported(
                                                  index
                                              )}
                                              issuer={
                                                  Array.isArray(cred.issuer)
                                                      ? cred.issuer[0]
                                                      : cred.issuer
                                              }
                                              selectCred={setSelectedCredential}
                                          />
                                      )
                                  }
                              )
                            : null}
                        {/* TODO: Uncomment if search can be done in backend. BE Pagination not compatible with FE Search */}
                        {/* {paginationData && showPagination ? (
                            <div className={styles.paginationContainer}>
                                <GaPagination {...paginationData} />
                            </div>
                        ) : null} */}
                        {!!selectedCredential ? (
                            <CredentialCardDetail
                                show={!!selectedCredential}
                                selectCred={setSelectedCredential}
                                hideModal={() =>
                                    setSelectedCredential(undefined)
                                }
                                showConnectionsAndDelete={true}
                                deleteCred={deleteCred}
                                didAlias={didAlias || selectedValue || ''}
                                {...selectedCredential}
                                issuer={
                                    Array.isArray(selectedCredential?.issuer)
                                        ? selectedCredential?.issuer[0]
                                        : selectedCredential?.issuer
                                }
                            />
                        ) : null}
                    </div>
                ) : (
                    <NoCredentialsPanel
                        showScanModal={setShowScanModal}
                        setFile={selectFile}
                    />
                )
            ) : (
                <div className={styles.credentialsContainer}>
                    <CardListSkeleton
                        cardsNumber={9}
                        className={styles.credSkeleton}
                    />
                </div>
            )}
            {showScanModal && scanCurrentStep ? (
                <scanCurrentStep.content
                    hideModal={() => {
                        setScanCurrentStep(scanRequestSteps[0])
                        setShowScanModal(false)
                        dispatch(clearModalState())
                    }}
                    setScanCurrentStep={setScanCurrentStep}
                    scanCurrentStep={scanCurrentStep}
                    scanSteps={scanRequestSteps}
                />
            ) : null}
        </div>
    )
}
