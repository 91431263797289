import { t } from 'i18next'
import {
    licensesToChooseFromWeb,
    selectPeriodOptions,
} from '../features/common/licenses/licenses.constants'
import {
    ILimitsModel,
    IPriceModel,
    ITierModel,
    MetricCode,
} from '../interfaces/interfaces'
import { formatNumberToCmpct } from './formatDataUtil'
import { formatNumbersDefaultOptions } from '../data/globalVar'

const monthValue = selectPeriodOptions[0]?.value
const monthLabel = selectPeriodOptions[0]?.text
const yearValue = selectPeriodOptions[1]?.value
const yearLabel = selectPeriodOptions[1]?.text

/* Return the price of a license according to selected period, if ot exist */
export const getPrice = (prices?: any[], selectedPeriod?: string) => {
    const selectedPeriodPrice = prices?.find(
        (el) => el.recurringInterval === selectedPeriod
    )

    return selectedPeriodPrice
        ? formatAmount(selectedPeriodPrice?.amount)
        : undefined
}

export const formatAmount = (amount?: number) =>
    amount ? amount / 100 : undefined

/* Return if a license is lower (in period or plan) than current license */
export const licenseIsLowerThanCurrent = (
    tier,
    currentLicense,
    currentSubscriptionPeriod,
    selectedPeriod
) => {
    const currentLicensePrices = currentLicense?.prices
    const currentLicenseIsYearly = currentSubscriptionPeriod === 'year'
    const selectedPeriodIsMonthly = selectedPeriod === 'month'
    const tierPrices = tier?.prices
    const tierIsSameTypeAsCurrent =
        currentLicense?.licenseType === tier?.licenseType

    return (
        currentLicensePrices &&
        tierPrices &&
        (currentLicensePrices[0]?.amount > tierPrices[0]?.amount ||
            (currentLicenseIsYearly &&
                selectedPeriodIsMonthly &&
                tierIsSameTypeAsCurrent))
    )
}

/* Return if a tier is the same as the active one */
export const getIfProductIsSameAsCurrent = (tier, subscriptionInfo) =>
    subscriptionInfo?.currentTierId === tier?.id

/* Return if an user wants to cancel subscription, not downgrade it */
export const getIfCancelSubscription = (
    tier,
    currentSubscriptionPeriod,
    selectedPeriod,
    subscriptionInfo
) => {
    const selectedPeriodIsSameAsCurrent =
        currentSubscriptionPeriod?.toLowerCase() === selectedPeriod

    return (
        !!getIfProductIsSameAsCurrent(tier, subscriptionInfo) &&
        selectedPeriodIsSameAsCurrent
    )
}

/* Returns the price id of a tier whose recurringInterval
 * matches the selected period (monthly or annual)
 */
export const getPriceId = (tier, selectedPeriod) =>
    tier?.prices?.find((el) => {
        return (
            el.recurringInterval?.toLowerCase() === selectedPeriod.toLowerCase()
        )
    })?.id

/* Returns the tier whose name matches the one passed by parameter */
export const findLicense = (name: string, tiers: any[]) => {
    const lowerCaseName = name?.toLocaleLowerCase()
    return tiers?.find(
        (el) => !!el?.name?.toLowerCase()?.includes(lowerCaseName)
    )
}

/* Returns the tier choosen matching it with the current path. Used for web Pricing view */
export const getLicenseFromPath = () => {
    const urlPathName = window?.location?.pathname
    const choosenLicense = urlPathName?.substring(7, urlPathName?.length)

    return choosenLicense
}

/* Returns if there is a tier selected from the web */
export const isLicenseSelectedFromWeb = () => {
    const choosenLicense = getLicenseFromPath()
    return licensesToChooseFromWeb?.includes(choosenLicense)
}

/* Returns if the subscription change is an upgrade from current tier */
export const isAnUpgrade = (
    tier,
    currentLicense,
    currentSubscriptionPeriod,
    selectedPeriod
) => {
    const currentLicensePrices = currentLicense?.prices
    const tierPrices = tier?.prices

    const tierIsSameTypeAsCurrent =
        currentLicense?.licenseType === tier?.licenseType
    const licenseIsUpperThanCurrent =
        currentLicensePrices &&
        tierPrices &&
        currentLicensePrices[0]?.amount < tierPrices[0]?.amount
    const currentLicenseIsYearly = currentSubscriptionPeriod === 'year'
    const selectedPeriodIsYearly = selectedPeriod === 'year'

    return (
        licenseIsUpperThanCurrent ||
        (tierIsSameTypeAsCurrent &&
            !currentLicenseIsYearly &&
            selectedPeriodIsYearly)
    )
}

const getLicenseAndPeriodOption = (license, period) => {
    const value = period === 'month' ? monthValue : yearValue
    const label = period === 'month' ? monthLabel : yearLabel

    return {
        value: license?.licenseType + '-' + value,
        text: license?.licenseType + ' - ' + t(label) || '',
    }
}

export const getSelectableOptionsForUpgrade = (
    currentLicense?: any,
    currentPeriod?: string,
    licenses?: ITierModel[]
) => {
    const upgradeOptions: { value: string; text: string }[] = []
    licenses?.map((license) => {
        const data = getLicenseConstants(currentLicense, license)

        if (data?.licenseIsUpperThanCurrent) {
            upgradeOptions?.push(data?.monthOption)
            upgradeOptions?.push(data?.yearOption)
        } else if (data?.tierIsSameTypeAsCurrent) {
            upgradeOptions?.push(data?.yearOption)
        } else if (!data?.currentLicenseIsEnterprise) {
            upgradeOptions?.push(data?.monthOption)
            upgradeOptions?.push(data?.yearOption)
        } else if (
            data?.currentLicenseIsEnterprise &&
            currentPeriod === 'month'
        ) {
            upgradeOptions?.push(data?.yearOption)
        }
    })

    return upgradeOptions
}

const getLicenseConstants = (currentLicense, license) => {
    const currentLicensePrices = currentLicense?.prices
    const tierPrices = license?.prices

    const tierIsSameTypeAsCurrent =
        currentLicense?.licenseType === license?.licenseType
    const licenseIsUpperThanCurrent =
        tierPrices &&
        currentLicensePrices &&
        currentLicensePrices[0]?.amount > tierPrices[0]?.amount

    const currentLicenseIsEnterprise =
        currentLicense?.licenseType === 'Enterprise'

    const monthOption = getLicenseAndPeriodOption(license, 'month')
    const yearOption = getLicenseAndPeriodOption(license, 'year')

    return {
        licenseIsUpperThanCurrent,
        tierIsSameTypeAsCurrent,
        currentLicenseIsEnterprise,
        monthOption,
        yearOption,
    }
}

export const getSelectableOptionsForDowngrade = (
    currentLicense?: any,
    currentPeriod?: string,
    licenses?: ITierModel[]
) => {
    const downgradeOptions: { value: string; text: string }[] = []
    licenses?.map((license) => {
        const data = getLicenseConstants(currentLicense, license)

        if (data?.licenseIsUpperThanCurrent) {
            downgradeOptions?.push(data?.monthOption)
            downgradeOptions?.push(data?.yearOption)
        } else if (data?.tierIsSameTypeAsCurrent) {
            downgradeOptions?.push(data?.yearOption)
        } else if (!data?.currentLicenseIsEnterprise) {
            downgradeOptions?.push(data?.monthOption)
            downgradeOptions?.push(data?.yearOption)
        } else if (
            data?.currentLicenseIsEnterprise &&
            currentPeriod === 'year'
        ) {
            downgradeOptions?.push(data?.monthOption)
        }
    })

    return downgradeOptions
}

export const getTiersLicenses = (tiers, listLicenses) => {
    const licenses = new Array()

    tiers?.map((tier) => {
        const correspondingLicense = listLicenses?.find((license) => {
            const tierName = tier?.name?.toLowerCase()
            const licenseName = license?.name?.toLowerCase()
            return !!tierName?.includes(licenseName)
        })
        if (correspondingLicense) {
            licenses?.push(correspondingLicense)
        }
    })
    return licenses?.length ? licenses : undefined
}

/**
 * Returns a limit of an array searching by code
 */
export const getLimit = (code: MetricCode, limits?: ILimitsModel[]) => {
    const emptyLimit = {
        Code: code,
        Value: 0,
        Description: '',
        Active: true,
    }

    return limits?.find((limit) => limit?.Code === code) || emptyLimit
}

/**
 * Returns a tier limits records by feature name
 */
export const getTierLimits = (tier?: ITierModel) => {
    const limits = tier?.limits
    return {
        issuanceTemplates: getLimit(MetricCode.Ctf1, limits),
        issuedCredentials: getLimit(MetricCode.Ctf2, limits),
        apiKeys: getLimit(MetricCode.Cmn2, limits),
        activeUsers: getLimit(MetricCode.Cnt2, limits),
        verificationTemplates: getLimit(MetricCode.Cnt1, limits),
        dids: getLimit(MetricCode.Cmn1, limits),
    }
}

/**
 * Returns a tier limits records by feature name
 */
export const getTierLimitsValues = (tier?: ITierModel) => {
    const limits = tier?.limits

    return {
        issuanceTemplates: getLimit(MetricCode.Ctf1, limits)?.Value,
        issuedCredentials: getLimit(MetricCode.Ctf2, limits)?.Value,
        apiKeys: getLimit(MetricCode.Cmn2, limits)?.Value,
        activeUsers: getLimit(MetricCode.Cnt2, limits)?.Value,
        verificationTemplates: getLimit(MetricCode.Cnt1, limits)?.Value,
        dids: getLimit(MetricCode.Cmn1, limits)?.Value,
    }
}

/**
 * Returns a tier limit quantity data to display
 * If limit is -1, it will return an infinite symbol
 */
export const getTierLimitToDisplay = (featureLimit?: number | string) => {
    let formattedFeatureLimit

    if (typeof featureLimit === 'number') {
        formattedFeatureLimit =
            featureLimit === -1
                ? '∞'
                : formatNumberToCmpct(featureLimit, formatNumbersDefaultOptions)
    } else if (featureLimit === '-1') {
        formattedFeatureLimit = '∞'
    } else {
        formattedFeatureLimit = featureLimit
    }

    return formattedFeatureLimit
}

export const getTierMonthlyPrices = (prices?: IPriceModel[]) => {
    return (
        prices?.filter((price) => price.recurringInterval === 'month') ||
        undefined
    )
}

export const getTierYearlyPrices = (prices?: IPriceModel[]) => {
    return (
        prices?.filter((price) => price.recurringInterval === 'year') ||
        undefined
    )
}

export const getTierActivePriceMonthly = (prices?: IPriceModel[]) => {
    let activePrice
    activePrice =
        prices?.find((price) => {
            return price.recurringInterval === 'month' && price?.active === true
        }) || undefined
    return activePrice
}

export const getTierActivePriceYearly = (prices?: IPriceModel[]) => {
    return (
        prices?.find(
            (price) =>
                price.recurringInterval === 'year' && price?.active === true
        ) || undefined
    )
}

/* Return the price of a license according to selected period, if ot exist */
export const getPriceById = (prices?: any[], currentPriceId?: string) => {
    const selectedPeriodPrice = prices?.find((el) => el.id === currentPriceId)

    return selectedPeriodPrice
}

export const getIfProductHasAmount = (
    tier?: ITierModel,
    selectedPeriod?: string
) => {
    const priceActiveMonthly = getTierActivePriceMonthly(tier?.prices)
    const priceActiveYearly = getTierActivePriceYearly(tier?.prices)
    const price =
        selectedPeriod === 'month' ? priceActiveMonthly : priceActiveYearly
    const hasAmount = !!price?.amount
    return hasAmount
}

export const getIfProductHasTrialDays = (
    tier?: ITierModel,
    selectedPeriod?: string
) => {
    const priceActiveMonthly = getTierActivePriceMonthly(tier?.prices)
    const priceActiveYearly = getTierActivePriceYearly(tier?.prices)
    const price =
        selectedPeriod === 'month' ? priceActiveMonthly : priceActiveYearly
    const hasTrialDays = !!price?.numTrialDays
    return hasTrialDays
}

export const getTrialDaysNumber = (
    tier?: ITierModel,
    selectedPeriod?: string
) => {
    const priceActiveMonthly = getTierActivePriceMonthly(tier?.prices)
    const priceActiveYearly = getTierActivePriceYearly(tier?.prices)
    const price =
        selectedPeriod === 'month' ? priceActiveMonthly : priceActiveYearly
    return price?.numTrialDays
}

/* Returns the eligible recurringIntervals of a tier's prices  */
export const getPriceIntervalOptions = (options: any[], tier?: ITierModel) => {
    const tierHasMonthlyPrice = getTierActivePriceMonthly(tier?.prices)
    const tierHasYearlyPrice = getTierActivePriceYearly(tier?.prices)

    const priceIntervals = new Array()
    tierHasMonthlyPrice ? priceIntervals?.push(options[0]) : null
    tierHasYearlyPrice ? priceIntervals?.push(options[1]) : null

    return priceIntervals
}

/* Returns the eligible recurringIntervals of all tier's prices  */
export const getSiwtchIntervalPeriodOptions = (
    options: any[],
    tiers?: ITierModel[]
) => {
    const thereIsMensualPrice = new Array()
    const thereIsAnualPrice = new Array()

    tiers?.map((tier) => {
        const tierHasMonthlyPrice = getTierActivePriceMonthly(tier?.prices)
        const tierHasYearlyPrice = getTierActivePriceYearly(tier?.prices)

        tierHasMonthlyPrice ? thereIsMensualPrice?.push(tier) : null
        tierHasYearlyPrice ? thereIsAnualPrice?.push(tier) : null
    })
    const priceIntervals = new Array()
    thereIsMensualPrice?.length ? priceIntervals?.push(options[0]) : null
    thereIsAnualPrice?.length ? priceIntervals?.push(options[1]) : null

    return priceIntervals
}

/* Returns the eligible tiers for request a subscription  */
export const getTiersOptions = (tiers?: ITierModel[]) => {
    const options = new Array()
    tiers?.map((tier) => {
        const tierHasPrices = tier?.prices?.length
        if (tierHasPrices) {
            options?.push({ text: tier?.name, value: tier?.id })
        }
    })
    return options
}

export const getIfTierHasInterval = (
    tier: ITierModel,
    siwtchIntervalPeriodOptions,
    switchPeriodValue: string
) => {
    const intervalValue =
        siwtchIntervalPeriodOptions?.length > 1
            ? switchPeriodValue
            : siwtchIntervalPeriodOptions[0]?.value || 'month'

    const tierHasInterval =
        (intervalValue === 'month' &&
            getTierActivePriceMonthly(tier?.prices)) ||
        (intervalValue === 'year' && getTierActivePriceYearly(tier?.prices))

    return !!tierHasInterval
}
