import React, { Dispatch, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import CreateBlock from '../../../Components/CreateBlock/CreateBlock'
import { history } from '../../../services/history'
import { formatStringDate } from '../../../utils/formatDataUtil'
import { getDids } from '../Dids/didActions'
import { didsScopes } from '../Dids/views/didList/didList.constants'
import { issuanceSelectors } from '../Issuance'
import { issuancesReadCreateScopes } from '../Issuance/issuance.constants'
import {
    clearFormState as clearIssuanceFormState,
    clearIssuancesGeneralState,
    getIssuances,
} from '../Issuance/issuanceActions'
import {
    clearFormState as clearVerifierFormState,
    clearVerifiersGeneralState,
    getVerifierPurposes,
} from '../Verifier/verifierActions'

import {
    getTiersAction,
    getSubscriptionInfoAction,
    preselectLicenseAction,
    setCurrentTierAction,
    setCurrentStepAction,
    setNewLicenseAction,
    setSelectedPeriodAction,
} from '../../common/licenses/licensesActions'
import { hideScreenLoaderAction } from '../../common/Loader/loaderActions'
import { screenLoaderActive } from '../../common/Loader/loaderSelectors'
import { setSelectedRoute } from '../../common/PanelScafolding/scafoldingActions'
import { activeRoute } from '../../common/PanelScafolding/scafoldingSelectors'
import { statsSelectors } from '../../common/Statistics'
import { getStatistics } from '../../common/Statistics/statisticsActions'
import { userSelectors } from '../../common/Team/tabsContent/users/store'
import { getUsers } from '../../common/Team/tabsContent/users/store/usersActions'
import { usersReadCreateScopes } from '../../common/Team/tabsContent/users/store/team.constants'
import { configVerifierSelectors } from '../Verifier'
import { getVerifierConfigs } from '../Verifier/verifierActions'
import { verifierReadCreateScopes } from '../Verifier/data/verifier.constants'
import { licensesSelectors } from '../../common/licenses'
import { sessionSelectors } from '../../common/session'
import { deleteSession, getLicenses } from '../../common/session/sessionActions'
import { homeSelectors } from '.'
import * as hc from './home.constants'
import styles from './home.module.scss'
import Limits from './HomeComponents/limits/Limits'
import {
    getIsActiveSession,
    userHasScopes,
} from '../../../utils/permissionsUtil'
import { onPremise } from '../../../data/globalVar'
import cx from 'classnames'
import LicensesModal from './HomeComponents/licensesModal/LicensesModal'
import { getSignUpStatus } from '../../common/Login/loginSelectors'
import { ActionStatusTypes } from '../../../assets/globalConstants'
import { getStatisticHasFailed } from '../../common/Statistics/statisticsSelectors'
import {
    getPreselectedLicense,
    showActivatedAccountPanel,
} from '../../common/licenses/licensesSelectors'
import {
    creatingOrgLoading,
    getOrg,
} from '../../common/organization/organizationSelectors'
import { didsSelectors } from '../Dids'
import {
    setAUSelectedPeriodIsYear,
    setGeneralDataLoaded,
    setICSelectedPeriodIsYear,
    setShowAgeVerificationModal,
} from './homeActions'
import auth from '../../../services/auth'
import { subscriptionSteps } from '../../common/licenses/licenses.constants'
import { findLicense, getPriceById } from '../../../utils/licensesUtil'
import IssuedCredentialsStatistics from './HomeComponents/statistics/issuedCredentials/IssuedCredentials'
import ActiveUsersStatistics from './HomeComponents/statistics/activeUsers/ActiveUsersStatistics'
import { IGeneralSelectOption } from '../../../interfaces/interfaces'
import { featureOptionsAU } from './HomeComponents/statistics/activeUsers/activeUsersStatisticsConstants'
import { featureOptionsIC } from './HomeComponents/statistics/issuedCredentials/issuedCredentialsStatisticsConstants'
import CardListSkeleton from '../../../Components/molecules/skeletons/cardListSkeleton/CardListSkeleton'
import Loader from '../../../Components/loader/loader/Loader'
import { getCatalog } from '../../common/catalog/catalogActions'
import {
    getAppIntegrations,
    getScopes,
} from '../../../store/appIntegrations/appIntegrationsActions'
import { appIntegrationsScopes } from '../AppIntegrations/data/AppIntegrationsList.constants'

type IHomeProps = {}

export const getIsAvailableScope = (
    allowedScopes: string[],
    specificSopes?: string[]
) => {
    let isActive = false
    if (specificSopes?.length && allowedScopes?.length) {
        isActive = specificSopes?.every((ai) => allowedScopes.includes(ai))
    } else {
        isActive = true
    }
    return isActive
}

export const LoadData = (
    dispatch: Dispatch<any>,
    allowedScopes: string[],
    isTrialing?: boolean
): void => {
    dispatch(setGeneralDataLoaded(true))
    if (getIsAvailableScope(allowedScopes, issuancesReadCreateScopes)) {
        dispatch(getIssuances())
    }
    if (getIsAvailableScope(allowedScopes, verifierReadCreateScopes)) {
        dispatch(getVerifierConfigs())
    }
    if (getIsAvailableScope(allowedScopes, usersReadCreateScopes)) {
        dispatch(getUsers())
    }
    if (getIsAvailableScope(allowedScopes, didsScopes?.read)) {
        dispatch(getDids())
    }
    if (!isTrialing) {
        dispatch(getAppIntegrations())
    }
}

const Home: React.FC<IHomeProps> = ({}) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const isCreateOrgLoading = useSelector(creatingOrgLoading)
    const notifications = useSelector(homeSelectors.getNotifications)
    const licenses = useSelector(sessionSelectors.getLicenses)
    const issuances = useSelector(issuanceSelectors.getIssuances)
    const users = useSelector(userSelectors.getUsers)
    const didsLoading = useSelector(didsSelectors.isLoading)
    const verifiersLoading = useSelector(configVerifierSelectors.getIsLoading)
    const issuancesLoading = useSelector(issuanceSelectors.getIsLoading)
    const usersLoading = useSelector(userSelectors.loadingStatus)
    const subscriptionInfo = useSelector(licensesSelectors.getSubscriptionInfo)
    const allowedScopes = useSelector(sessionSelectors.getAllowedScopes)
    const currentMenuOptionSelected = useSelector(activeRoute)
    const userLicense = useSelector(sessionSelectors.getLicenses)
    const currentLicense = useSelector(licensesSelectors.getCurrentProduct)
    const subscriptionInfoIsLoading = useSelector(
        licensesSelectors.getSubscriptionInfoIsLoading
    )
    const showScreenLoader = useSelector(screenLoaderActive)
    const tokenExpiration = useSelector(sessionSelectors.getTokenExpiration)
    const tokenOrg = useSelector(sessionSelectors.getUserOrganization)
    const org = useSelector(getOrg)

    const usedFeatures = useSelector(statsSelectors.getUsedFeaturesNumber)
    const getStatsLoading = useSelector(statsSelectors.getStatisticsLoading)

    const licensesAreLoading = useSelector(
        sessionSelectors.getLicensesAreLoading
    )
    const availableScopes = useSelector(sessionSelectors?.getAllowedScopes)
    const newLicense = useSelector(licensesSelectors.getNewLicense)
    const tiers = useSelector(licensesSelectors.getProviderTiersAndFeatures)

    const currentLicenseType = tiers?.find(
        (tier) => tier.id === subscriptionInfo?.currentTierId
    )

    const subscriptionIsUpdating = useSelector(
        licensesSelectors.updatingSubscriptionLoading
    )
    const statisticsIsLoading = useSelector(statsSelectors.getStatisticsLoading)
    const subscriptionIsBeingCancelled = useSelector(
        licensesSelectors.cancelSubscriptionLoading
    )
    const downgradeIsBeingCancelled = useSelector(
        licensesSelectors.cancelDowngradeLoading
    )
    const loaderScreenActive = useSelector(screenLoaderActive)
    const currentSubscriptionPeriod = useSelector(
        licensesSelectors.getCurrentSubscriptionPeriod
    )
    const preselectLicense = useSelector(getPreselectedLicense)
    const selectedPeriod = useSelector(licensesSelectors.getSelectedPeriod)
    const tiersAreLoading = useSelector(licensesSelectors.tiersAreLoading)
    const statisticHasFailed = useSelector(getStatisticHasFailed)
    const sessionRead = useSelector(sessionSelectors.getSessionRead)
    const isLicenseRegistrationInProccess =
        useSelector(getSignUpStatus) === ActionStatusTypes.success
    const activatedAccount = useSelector(showActivatedAccountPanel)
    const dataLoaded = useSelector(homeSelectors.generalDataLoaded)

    const trialLicenseIncomplete =
        !tokenOrg?.length && preselectLicense === 'Starter'
    const newLicenseDate = formatStringDate(
        subscriptionInfo?.currentPeriodEnd,
        'DD/MM/YYYY'
    )
    const currentSubscriptionPeriodIsYear = currentSubscriptionPeriod === 'year'
    const thereIsDue = subscriptionInfo?.subscriptionStatus === 'past_due'
    const canceledLicense = subscriptionInfo?.subscriptionStatus === 'canceled'
    const licenseIsIncomplete = !!(
        !onPremise && subscriptionInfo?.subscriptionStatus === 'incomplete'
    )
    const unPaidLicense = subscriptionInfo?.subscriptionStatus === 'unpaid'
    const activeLicense = subscriptionInfo?.subscriptionStatus === 'active'
    const trialLicense =
        subscriptionInfo?.subscriptionStatus === 'trial' ||
        subscriptionInfo?.subscriptionStatus === 'trialing'

    const allowedToAccessTemplates =
        !!allowedScopes?.length ||
        activeLicense ||
        trialLicense ||
        unPaidLicense ||
        licenseIsIncomplete
    trialLicenseIncomplete
    const isNewUser = !!(
        availableScopes &&
        userHasScopes(['createOrganization'], availableScopes)
    )

    const currentPrice = getPriceById(
        currentLicenseType?.prices,
        subscriptionInfo?.currentPriceId
    )

    const currentPriceHasTrialDays = !!currentPrice?.numTrialDays

    const isNewUserToRegister =
        isNewUser &&
        tiers?.length &&
        !currentPriceHasTrialDays &&
        subscriptionInfo?.subscriptionStatus !== 'incomplete'

    const [switchPeriodValue, setPeriodChecked] = useState(
        selectedPeriod || 'month'
    )

    const [homeLoaded, setHomeLoaded] = useState(false)

    const [showSubcriptionsModal, setShowSubcriptionModal] =
        React.useState(false)

    const showFillOrgDataPanel =
        !tokenOrg?.length &&
        subscriptionInfo?.subscriptionStatus !== 'incomplete' &&
        preselectLicense === 'Starter'

    const hasReadAppIntegrationScope = !!(
        availableScopes &&
        userHasScopes(appIntegrationsScopes?.read, availableScopes)
    )

    useEffect(() => {
        const choosenLicenseFromWeb = auth?.getChoosenLicense()

        if (isNewUserToRegister) {
            !choosenLicenseFromWeb
                ? !org?.id &&
                  !tokenOrg &&
                  !showFillOrgDataPanel &&
                  setShowSubcriptionModal(true)
                : managePreselectedLicenseFromWeb(choosenLicenseFromWeb)
        }
    }, [isNewUser, tiers, tokenExpiration])

    useEffect(() => {}, [activatedAccount])

    useEffect(() => {}, [homeLoaded])

    useEffect(() => {
        if (sessionRead) {
            if (!!tokenExpiration) {
                currentMenuOptionSelected === 'home' &&
                    !isLicenseRegistrationInProccess &&
                    dispatch(setSelectedRoute('home'))
                // Just in cloud for now
                if (
                    !licenses &&
                    !licensesAreLoading &&
                    !onPremise &&
                    // subscriptionInfo?.subscriptionStatus && subscriptionInfo?.subscriptionStatus !== "incomplete"
                    !isNewUser
                ) {
                    dispatch(getLicenses())
                }
                if (
                    !currentLicense?.id &&
                    !subscriptionInfo?.currentTierId?.length &&
                    !subscriptionInfoIsLoading &&
                    !onPremise &&
                    !isNewUser
                ) {
                    // Just in cloud for now
                    !onPremise && dispatch(getSubscriptionInfoAction())
                }
            } else {
                !isLicenseRegistrationInProccess && goToLoginAndDeleteSession()
            }
        }
    }, [sessionRead, activatedAccount])

    useEffect(() => {
        if (
            sessionRead &&
            (subscriptionInfo?.paymentMethod?.id || currentPriceHasTrialDays)
        ) {
            if (!!tokenExpiration) {
                if (
                    !!allowedScopes?.length &&
                    currentMenuOptionSelected === 'home' &&
                    !isNewUser &&
                    !dataLoaded
                ) {
                    !verifiersLoading &&
                        !didsLoading &&
                        !usersLoading &&
                        !issuancesLoading &&
                        LoadData(dispatch, allowedScopes || [], trialLicense)
                }
            } else {
                !isLicenseRegistrationInProccess && goToLoginAndDeleteSession()
            }
        }
    }, [sessionRead, usedFeatures])
    useEffect(() => {
        if (!!tokenExpiration && (!!tokenOrg || onPremise)) {
            if (
                loaderScreenActive &&
                !subscriptionInfoIsLoading &&
                !subscriptionIsBeingCancelled &&
                !downgradeIsBeingCancelled &&
                !subscriptionIsUpdating
            ) {
                dispatch(hideScreenLoaderAction())
            }
            if (tiers && !currentLicense?.id) {
                if (subscriptionInfo?.currentTierId?.length) {
                    const currentLicense = tiers?.find((el) => {
                        return (
                            el?.id?.toLowerCase() ===
                            subscriptionInfo?.currentTierId?.toLowerCase()
                        )
                    })
                    dispatch(setCurrentTierAction(currentLicense))
                } else if (userLicense && userLicense[0]?.licenseType) {
                    const currentLicense = tiers?.find((el) => {
                        return (
                            el?.licenseType?.toLowerCase() ===
                            userLicense[0]?.licenseType?.toLowerCase()
                        )
                    })
                    dispatch(setCurrentTierAction(currentLicense))
                }
            }
        }
    }, [tiers?.length])

    useEffect(() => {
        if (!!tokenExpiration && (!!tokenOrg || onPremise || isNewUser)) {
            // Just in cloud for now
            if (
                !tiers?.length &&
                !tiersAreLoading &&
                !onPremise &&
                sessionRead
            ) {
                dispatch(getTiersAction())
            }
            // Just in cloud for now
            if (!statisticsIsLoading && getIsActiveSession(tokenExpiration)) {
                !onPremise && dispatch(getStatistics())
                allowedToAccessTemplates &&
                    (dispatch(getCatalog()), dispatch(getVerifierPurposes()))
                if (allowedToAccessTemplates && !trialLicense) {
                    dispatch(getAppIntegrations())
                    hasReadAppIntegrationScope && dispatch(getScopes())
                }
                dispatch(clearIssuanceFormState())
                dispatch(clearVerifierFormState())
                dispatch(clearVerifiersGeneralState())
                dispatch(clearIssuancesGeneralState())
            }
        }
    }, [currentLicense?.id])

    const managePreselectedLicenseFromWeb = (license?: string) => {
        const starterProduct = findLicense('starter', tiers)
        const essentialProduct = findLicense('essential', tiers)
        const profesionalProduct = findLicense('professional', tiers)

        switch (license) {
            case 'starter':
                starterProduct && selectStarter(starterProduct)
                auth?.setChoosenLicense('')
                break
            case 'essential':
                essentialProduct && selectLicense(essentialProduct)
                auth?.setChoosenLicense('')
                break
            case 'professional':
                profesionalProduct && selectLicense(profesionalProduct)
                auth?.setChoosenLicense('')
                break
            default:
                !showFillOrgDataPanel && setShowSubcriptionModal(true)
        }
    }

    const goToLoginAndDeleteSession = () => {
        dispatch(setSelectedRoute('login'))
        dispatch(deleteSession())
        history.push('/login')
    }

    const hideSubcriptionModal = () => {
        setShowSubcriptionModal(false)
    }

    const selectPeriod = (period: string) => {
        setPeriodChecked(period)
        dispatch(setSelectedPeriodAction(period))
    }

    const navigateChangeSubscription = () => {
        if (
            !(
                subscriptionIsUpdating ||
                subscriptionIsBeingCancelled ||
                downgradeIsBeingCancelled
            )
        ) {
            dispatch(setSelectedRoute('change-subscription'))
            history.push('change-subscription')
        }
    }

    const selectStarter = (license) => {
        dispatch(preselectLicenseAction('Starter'))
        dispatch(setNewLicenseAction(license))
        hideSubcriptionModal()
    }

    const selectLicense = (license) => {
        dispatch(setCurrentStepAction(subscriptionSteps[0]))
        dispatch(preselectLicenseAction(license?.licenseType))
        dispatch(setNewLicenseAction(license))
        navigateChangeSubscription()
        hideSubcriptionModal()
    }

    const shouldDisplayBlock = (type?: string): boolean => {
        switch (type) {
            case 'verifiers':
                return (
                    !!allowedScopes?.length && !thereIsDue && !canceledLicense
                )
            case 'issuances':
                return (
                    !!allowedScopes?.length && !thereIsDue && !canceledLicense
                )
            case 'age-verification':
                return (
                    !!allowedScopes?.length && !thereIsDue && !canceledLicense
                )
            // Commented until invitations are available
            // case 'notifications':
            //     return (
            //         !!allowedScopes?.length &&
            //         getIsAvailableScope(
            //             allowedScopes,
            //             issuancesReadCreateScopes
            //         ) &&
            //         !issuances?.length
            //     )
            // case 'users':
            //     return (
            //         !!allowedScopes?.length &&
            //         getIsAvailableScope(allowedScopes, usersReadCreateScopes) &&
            //         users?.length <= 1
            //     )
        }
        return false
    }

    const filterDatesOptionsAU: IGeneralSelectOption[] = [
        {
            id: 'W',
            text: 'dashboard.last7Days',
            value: '7',
            action: () => (
                setSelectedFilterDateAU('7'),
                dispatch(setAUSelectedPeriodIsYear(false))
            ),
        },
        {
            id: 'M',
            text: 'dashboard.last30Days',
            value: '30',
            action: () => (
                setSelectedFilterDateAU('30'),
                dispatch(setAUSelectedPeriodIsYear(false))
            ),
        },
        {
            id: 'Y',
            text: 'dashboard.last365Days',
            value: '365',
            action: () => (
                setSelectedFilterDateAU('365'),
                dispatch(setAUSelectedPeriodIsYear(true))
            ),
        },
    ]

    const filterDatesOptionsIC: IGeneralSelectOption[] = [
        {
            id: 'W',
            text: 'dashboard.last7Days',
            value: '7',
            action: () => (
                setSelectedFilterDateIC('7'),
                dispatch(setICSelectedPeriodIsYear(false))
            ),
        },
        {
            id: 'M',
            text: 'dashboard.last30Days',
            value: '30',
            action: () => (
                setSelectedFilterDateIC('30'),
                dispatch(setICSelectedPeriodIsYear(false))
            ),
        },
        {
            id: 'Y',
            text: 'dashboard.last365Days',
            value: '365',
            action: () => (
                setSelectedFilterDateIC('365'),
                dispatch(setICSelectedPeriodIsYear(true))
            ),
        },
    ]

    const displayAgeVerificationModal = () =>
        dispatch(setShowAgeVerificationModal(true))

    const [selectedFilterPeriodAU, setSelectedFilterDateAU] = useState<string>(
        filterDatesOptionsAU[0]?.value
    )

    const [selectedFilterPeriodIC, setSelectedFilterDateIC] = useState<string>(
        filterDatesOptionsIC[0]?.value
    )

    const loadingStatistics =
        useSelector(homeSelectors.getStastisticsStatus) ===
        ActionStatusTypes?.loading

    const loadingICStats =
        useSelector(homeSelectors.getICStastisticsStatus) ===
        ActionStatusTypes?.loading

    const blockStatus = (type) => {
        if (
            type === 'age-verification' &&
            (!subscriptionInfoIsLoading ||
                !!subscriptionInfo?.subscriptionStatus)
        ) {
            return trialLicense
                ? 'needUpgrade'
                : trialLicenseIncomplete
                ? 'noPermissions'
                : undefined
        }
    }

    return (
        <React.Fragment>
            <div className={styles.dashboard__header}>
                <h2 className={`${styles.title} ${cx('neutral1000 heading4')}`}>
                    {t(hc.homeHealine)}
                </h2>
            </div>
            {!(
                verifiersLoading ||
                issuancesLoading ||
                usersLoading ||
                subscriptionInfoIsLoading
            ) ? (
                <>
                    <div className={styles.firstSection}>
                        {allowedToAccessTemplates && !onPremise ? (
                            <div key="starting" className={styles.homeBlocks}>
                                {hc.blocks.map((e, index) => {
                                    return (
                                        shouldDisplayBlock(e?.type) && (
                                            <CreateBlock
                                                className={cx(
                                                    styles.homeBlocks__block,
                                                    !!blockStatus(e?.type) &&
                                                        styles.homeBlocks__disabled
                                                )}
                                                key={index}
                                                title={t(e.title)}
                                                description={e.description}
                                                route={e.route || '/home'}
                                                action={
                                                    e.type ===
                                                    'age-verification'
                                                        ? displayAgeVerificationModal
                                                        : e.action
                                                }
                                                status={blockStatus(e?.type)}
                                            ></CreateBlock>
                                        )
                                    )
                                })}
                            </div>
                        ) : null}

                        {!getStatsLoading ? (
                            (usedFeatures || trialLicenseIncomplete) &&
                            !onPremise && (
                                <div
                                    className={
                                        styles.subscriptionPanelContainerHome
                                    }
                                >
                                    <Limits
                                        licenseIsIncomplete={
                                            !!licenseIsIncomplete
                                        }
                                        trialLicenseIsIncomplete={
                                            trialLicenseIncomplete
                                        }
                                        isOnHome={true}
                                        statisticHasFailed={statisticHasFailed}
                                        userLicense={userLicense}
                                        tiers={tiers}
                                        currentLicense={currentLicense}
                                        subscriptionInfo={subscriptionInfo}
                                        subscriptionInfoIsLoading={
                                            subscriptionInfoIsLoading
                                        }
                                        subscriptionIsUpdating={
                                            subscriptionIsUpdating
                                        }
                                        statisticsIsLoading={
                                            statisticsIsLoading
                                        }
                                        subscriptionIsBeingCancelled={
                                            subscriptionIsBeingCancelled
                                        }
                                        downgradeIsBeingCancelled={
                                            downgradeIsBeingCancelled
                                        }
                                        loaderScreenActive={
                                            loaderScreenActive || false
                                        }
                                        currentSubscriptionPeriod={
                                            currentSubscriptionPeriod
                                        }
                                        currentLicenseType={currentLicenseType}
                                        newLicenseDate={newLicenseDate}
                                        currentSubscriptionPeriodIsYear={
                                            currentSubscriptionPeriodIsYear
                                        }
                                        usedFeatures={usedFeatures}
                                        tiersAreLoading={tiersAreLoading}
                                    />
                                </div>
                            )
                        ) : (
                            <div
                                className={cx(
                                    styles.subscriptionPanelContainerHome
                                )}
                            >
                                <CardListSkeleton
                                    className={cx(
                                        styles.subscriptionPanelContainerHome__skeleton
                                    )}
                                    cardsNumber={1}
                                />
                            </div>
                        )}
                    </div>
                    {showSubcriptionsModal ? (
                        <LicensesModal
                            show={!!showSubcriptionsModal}
                            selectStarter={selectStarter}
                            selectLicense={selectLicense}
                            navigateChangeSubscription={
                                navigateChangeSubscription
                            }
                            setShowSubcriptionModal={setShowSubcriptionModal}
                            switchPeriodValue={switchPeriodValue}
                            selectPeriod={selectPeriod}
                            setPeriodChecked={selectPeriod}
                        />
                    ) : null}

                    <div key="chart" className={styles.charts}>
                        {featureOptionsIC && (
                            <IssuedCredentialsStatistics
                                dataLoading={loadingICStats}
                                selectedFilterPeriod={selectedFilterPeriodIC}
                                setSelectedFilterDate={setSelectedFilterDateIC}
                                homeLoaded={homeLoaded}
                                setHomeLoaded={setHomeLoaded}
                            />
                        )}
                        {featureOptionsAU && (
                            <ActiveUsersStatistics
                                selectedFilterPeriod={selectedFilterPeriodAU}
                                homeLoaded={homeLoaded}
                                setHomeLoaded={setHomeLoaded}
                                setSelectedFilterDate={setSelectedFilterDateAU}
                            />
                        )}
                    </div>
                </>
            ) : (
                !showScreenLoader && <Loader className={styles.loader} />
            )}
        </React.Fragment>
    )
}

Home.defaultProps = {}

export default Home
