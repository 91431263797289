import cx from 'classnames'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { gaImages } from '../../../../../assets/globalConstants'
import Description from '../../../../../Components/atoms/texts/Description/Description'
import ScreenHeader from '../../../../../Components/molecules/headers/ScreenHeader/ScreenHeader'
import SearchableListHeader from '../../../../../Components/molecules/headers/SearchableListHeader/SearchableListHeader'
import PermissionsPanel from '../../../../../Components/molecules/notification/panels/permissionsPanel/PermissionsPanel'
import TableSkeleton from '../../../../../Components/molecules/skeletons/tableSkeleton/TableSkeleton'
import WarningModal from '../../../../../Components/molecules/table/dynamicTable/warninModal/WarningModal'
import BasicCard from '../../../../../Components/organisms/Cards/BasicCard'
import {
    IApiKeyModel,
    IIssuanceConfigItemModel,
} from '../../../../../interfaces/interfaces'
import {
    getIsActiveSession,
    userHasScopes,
} from '../../../../../utils/permissionsUtil'
import { getCatalog } from '../../../../common/catalog/catalogActions'
import { getDids } from '../../../Dids/didActions'
import { didsScopes } from '../../../Dids/views/didList/didList.constants'
import { issuanceSelectors } from '../../../Issuance'
import { issuancesScopes } from '../../../Issuance/issuance.constants'
import {
    clearFormState,
    deleteIssuance,
    editIssuance,
    getIssuances,
} from '../../../Issuance/issuanceActions'
import { licensesSelectors } from '../../../../common/licenses'
import { errorPanelReachedFeatures } from '../../../../common/PanelScafolding/private/panelScafoldingPrivateConstants'
import { sessionSelectors } from '../../../../common/session'
import { statsSelectors } from '../../../../common/Statistics'
import { getStatistics } from '../../../../common/Statistics/statisticsActions'
import {
    headerIssuersButton,
    propertiesToSearchIssuers,
} from '../../services.constants'
import { onPremise } from '../../../../../data/globalVar'
import { apiKeysSelectors } from '../../../ApiKeys'
import dataService from '../../../../../services/dataService'
import { getTierLimitsValues } from '../../../../../utils/licensesUtil'
import { organizationScopes } from '../../../../common/organization/views/general/organization.constants'
import { orgSelectors } from '../../../../common/organization'
import { getOrgAction } from '../../../../common/organization/organizationActions'

type IIssuersListProps = {}

const IssuersList: React.FC<IIssuersListProps> = () => {
    const history = useHistory()
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const isLoading = useSelector(issuanceSelectors.getIsLoading)
    const issuers = useSelector(issuanceSelectors.getIssuances)

    const [issuances, setIssuances] =
        React.useState<IIssuanceConfigItemModel[]>()
    const [state, setState] = React.useState<{
        searchedResult: IIssuanceConfigItemModel[]
    }>({ searchedResult: issuances?.slice() || [] })
    const [showDeleteWarning, setShowDeleteModal] = React.useState(false)
    const [selectedIssuance, setSelectedIssuance] = React.useState(undefined)

    const showDeleteModal = (item) => {
        setSelectedIssuance(item)
        setShowDeleteModal(true)
    }
    const hideDeleteModal = () => {
        setSelectedIssuance(undefined)
        setShowDeleteModal(false)
    }

    const currentLicense = useSelector(licensesSelectors.getCurrentProduct)
    const excededLimitsState = useSelector(licensesSelectors.excededLimits)
    const usedFeatures = useSelector(statsSelectors.getUsedFeaturesNumber)
    const apiKeysListUnfiltered = useSelector(apiKeysSelectors.getApiKeys)
    const deletedConfig = useSelector(issuanceSelectors.getDeletedConfigId)
    const statisticsIsLoading = useSelector(statsSelectors.getStatisticsLoading)
    const availableScopes = useSelector(sessionSelectors?.getAllowedScopes)
    const orgId = useSelector(sessionSelectors.getUserOrganization)
    const orgIsLoading = useSelector(orgSelectors.getOrgIsLoading)

    const hasReadScope = !!(
        availableScopes && userHasScopes(issuancesScopes?.read, availableScopes)
    )
    const canReadDids = !!(
        availableScopes && userHasScopes(didsScopes?.read, availableScopes)
    )
    const canEdit = !!(
        availableScopes && userHasScopes(issuancesScopes?.edit, availableScopes)
    )
    const canDelete = !!(
        availableScopes &&
        userHasScopes(issuancesScopes?.delete, availableScopes)
    )

    const limitsAreExceded =
        excededLimitsState?.length &&
        excededLimitsState?.includes(
            errorPanelReachedFeatures.issuance_templates
        )

    const limitsAreReached =
        usedFeatures?.issuanceTemplates ===
        getTierLimitsValues(currentLicense)?.issuanceTemplates

    const apiKeysList = deletedConfig
        ? apiKeysListUnfiltered?.filter((ak: IApiKeyModel) =>
              ak.ssi_operations?.includes(deletedConfig)
          )
        : []

    const canReadOrg = !!(
        availableScopes &&
        userHasScopes(organizationScopes.read, availableScopes)
    )

    useEffect(() => {
        if (!issuers?.length && hasReadScope) {
            dispatch(getIssuances())
        }
        if (!statisticsIsLoading && getIsActiveSession()) {
            // Just in cloud for now
            !onPremise && dispatch(getStatistics())
        }
        if (orgId && canReadOrg && !orgIsLoading) {
            // Just in cloud for now
            !onPremise && dispatch(getOrgAction())
        }
    }, [])
    useEffect(() => {
        setIssuances(issuers || [])
    }, [issuers?.length])
    useEffect(() => {
        setState({ searchedResult: issuances?.slice() || [] })
    }, [issuances])
    useEffect(() => {
        deletedConfig && updateApiKeys(deletedConfig)
    }, [deletedConfig])

    const editIssuer = (issuance: IIssuanceConfigItemModel) => {
        const position = issuers?.filter((i) => i.id === issuance.id)
        if (!!position?.length) {
            clearVerifierState()
            getCatalogAndDids()
            dispatch(editIssuance(position[0]))
            history.push('/config-issuer')
        }
    }

    const clearVerifierState = () => {
        dispatch(clearFormState())
    }
    const getCatalogAndDids = () => {
        dispatch(getCatalog())
        canReadDids && dispatch(getDids())
    }
    const handleSearchedChange = (items) => {
        if (!!items) {
            setState({ searchedResult: items })
        }
    }

    const deleteItem = (item: IIssuanceConfigItemModel) => {
        dispatch(deleteIssuance(item?.id))
    }

    const deleteModalButtons = {
        primary: { label: 'public.delete', function: deleteItem },
        secondary: { label: 'public.cancel', function: hideDeleteModal },
    }

    const itemActions = [
        {
            label: 'public.edit',
            action: editIssuer,
            hasScope: canEdit,
        },
        {
            label: 'public.delete',
            action: showDeleteModal,
            hasScope: canDelete,
            color: 'red',
        },
    ]

    // Change Api Keys SSI Configs
    let apiKeysWithSSIConfigRemovedQtty = 0
    const apiKeysWithMoreSSIConfigs = apiKeysList?.filter(
        (ak: IApiKeyModel) => ak.ssi_operations?.length !== 1
    )

    const removeConfigFromApiKey = async (
        apiKey: any,
        apiKeyId: string,
        configID: string
    ) => {
        let updating = false
        const newApyKey = apiKey
        const newSSIConfigs = newApyKey.ssi_operations?.filter(
            (e) => e !== configID
        )
        newApyKey.ssi_operations = newSSIConfigs
        const simplifiedData = newApyKey as IApiKeyModel
        if (!updating) {
            updating = true
            while (
                apiKeysWithSSIConfigRemovedQtty <
                apiKeysWithMoreSSIConfigs?.length
            ) {
                apiKeysWithSSIConfigRemovedQtty =
                    apiKeysWithSSIConfigRemovedQtty + 1
                const callDone =
                    await dataService?.updateApiKeyAccordingSSIConfig(
                        simplifiedData,
                        apiKeyId
                    )
                if (callDone !== undefined) {
                    updating = false
                }
            }
        }
    }

    const updateApiKeys = (config: string) => {
        if (apiKeysWithMoreSSIConfigs?.length) {
            apiKeysWithMoreSSIConfigs?.map((apiKey: IApiKeyModel) => {
                removeConfigFromApiKey(apiKey, apiKey?.id, config)
            })
        }
    }

    return (
        <>
            <ScreenHeader
                title={'services.listIssuersTitle'}
                subText={'services.listIssuersDescription'}
                titleClassname={'heading5'}
                button={{
                    ...headerIssuersButton,
                    disabled:
                        (!!limitsAreExceded || limitsAreReached) && !onPremise,
                    disabledTooltip: limitsAreReached && !onPremise,
                }}
                buttonScopes={issuancesScopes.create}
                buttonFunction={() => {
                    clearVerifierState()
                    getCatalogAndDids()
                    history.push('/create-issuer')
                }}
            />
            {hasReadScope ? (
                <>
                    <SearchableListHeader
                        title={''}
                        isLoading={isLoading}
                        options={issuers || []}
                        propertiesToSearch={propertiesToSearchIssuers}
                        handleSearchedChange={handleSearchedChange}
                    />

                    {!isLoading ? (
                        issuers && issuers?.length > 0 ? (
                            <div className={cx('rowLayout')}>
                                {state.searchedResult?.map((e, index) => {
                                    return (
                                        <BasicCard
                                            item={e}
                                            name={e.id}
                                            key={e.id + index}
                                            description={{
                                                text: 'services.credentialIssuance',
                                            }}
                                            actions={itemActions}
                                            buttonFunction={editIssuer}
                                        />
                                    )
                                })}
                            </div>
                        ) : (
                            <Description
                                text={t('services.noIssuersCreated')}
                            />
                        )
                    ) : (
                        <TableSkeleton columnsNumber={3} rowsNumber={10} />
                    )}
                    {showDeleteWarning ? (
                        <WarningModal
                            primaryButton={deleteModalButtons.primary}
                            secondaryButton={deleteModalButtons.secondary}
                            hideModal={hideDeleteModal}
                            item={selectedIssuance}
                        />
                    ) : null}
                </>
            ) : (
                <PermissionsPanel scopes={issuancesScopes?.read} readScope />
            )}
        </>
    )
}

export default IssuersList
