import { t } from 'i18next'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { licensesSelectors } from '../../..'
import { ReactComponent as CloseIcon } from '../../../../../../assets/images/close.svg'
import styles from './featuresModal.module.scss'
import cx from 'classnames'
import LicensesTable from './licensesTable/LicensesTable'
import { licensesInfoToggles, listLicenses } from './licensesTableData'
import LicensesTableMobile from './licensesTableMobile/LicensesTableMobile'
import { getTiersLicenses } from '../../../../../../utils/licensesUtil'

type IFeaturesModalProps = {
    show: boolean
    switchPeriodValue: string
    hideModal: () => void
    setPeriodChecked: (x) => void
}

const FeaturesModal: React.FC<IFeaturesModalProps> = (props) => {
    const { show, switchPeriodValue, hideModal, setPeriodChecked } = props

    const selectedPeriod = useSelector(licensesSelectors.getSelectedPeriod)
    const tiers = useSelector(licensesSelectors.getProviderTiersAndFeatures)
    const tierLicenses = getTiersLicenses(tiers, listLicenses)

    const [selectedLicense, setSelectedLicense] = React.useState(0)

    useEffect(() => {}, [selectedPeriod])

    return (
        <div
            className={`${styles.backgroundLayer} ${
                show ? styles.visibleModal : styles.hiddenModal
            }`}
        >
            <div className={`${styles.modal}`}>
                <div className={styles.modal__header}>
                    <div
                        className={styles.modal__header__close}
                        onClick={() => hideModal()}
                    >
                        <CloseIcon />
                    </div>
                    <div className={styles.modal__header__title}>
                        <h3 className={cx('heading5 neutral1000')}>
                            {t('subscription.fullFeature')}
                        </h3>
                    </div>
                </div>
                <div className={styles.tableContainer}>
                    <div>
                        <LicensesTable
                            tiers={tierLicenses}
                            switchPeriodValue={switchPeriodValue}
                            infoToggles={licensesInfoToggles}
                            setPeriodChecked={setPeriodChecked}
                        />
                        <LicensesTableMobile
                            licenseIndex={selectedLicense}
                            license={
                                tierLicenses && tierLicenses[selectedLicense]
                            }
                            switchPeriodValue={switchPeriodValue}
                            infoToggles={licensesInfoToggles}
                            selectLicense={setSelectedLicense}
                            setPeriodChecked={setPeriodChecked}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FeaturesModal
