import * as React from 'react'
import cx from 'classnames'
import styles from './iconDataCell.module.scss'
import { gaImages } from '../../../../../../../../../../assets/globalConstants'

export type IIconDataCellProps = {
    data: any
}

const IconDataCell: React.FC<IIconDataCellProps> = (props) => {
    const { data } = props
    return (
        <>
            <div className={`${cx('bodyBoldSM')}`}>
                <img
                    className={styles?.icon}
                    src={data ? gaImages.check?.src : gaImages.close?.src}
                ></img>
            </div>
        </>
    )
}

export default IconDataCell
