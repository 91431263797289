import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { didsSelectors } from '../..'
import Description from '../../../../../Components/atoms/texts/Description/Description'
import ScreenHeader from '../../../../../Components/molecules/headers/ScreenHeader/ScreenHeader'
import SearchableListHeader from '../../../../../Components/molecules/headers/SearchableListHeader/SearchableListHeader'
import PermissionsPanel from '../../../../../Components/molecules/notification/panels/permissionsPanel/PermissionsPanel'
import TableSkeleton from '../../../../../Components/molecules/skeletons/tableSkeleton/TableSkeleton'
import WarningModal from '../../../../../Components/molecules/table/dynamicTable/warninModal/WarningModal'
import { IDIDModel } from '../../../../../interfaces/interfaces'
import {
    getTotalPageItems,
    paginate,
} from '../../../../../utils/paginationUtil'
import {
    getIsActiveSession,
    userHasScopes,
} from '../../../../../utils/permissionsUtil'
import { getCatalog } from '../../../../common/catalog/catalogActions'
import { licensesSelectors } from '../../../../common/licenses'
import { errorPanelReachedFeatures } from '../../../../common/PanelScafolding/private/panelScafoldingPrivateConstants'
import { sessionSelectors } from '../../../../common/session'
import { statsSelectors } from '../../../../common/Statistics'
import { getStatistics } from '../../../../common/Statistics/statisticsActions'
import {
    addIssuerType,
    addVerifierType,
    clearDid,
    deleteDid,
    deleteIssuerType,
    deleteVerifierType,
    getDids,
    selectDid,
} from '../../didActions'
import { getSelectedDid, getSelectedDidBaseId } from '../../didSelectors'
import {
    didsScopes,
    headerButton,
    headersDidsTable,
    propertiesToSearch,
} from './didList.constants'
import DidModal from './modal/DidModal'
import DidsTable from './table/DidsTable'
import { onPremise } from '../../../../../data/globalVar'
import BasicCard from '../../../../../Components/organisms/Cards/BasicCard'
import { shortValue } from '../../../../../utils/formatDataUtil'
import { getTierLimitsValues } from '../../../../../utils/licensesUtil'
import { defaultPageSize } from '../../../../../Components/Pagination/paginationConstants'

type IDidsListProps = {}

type ISearchState = {
    searched: string
    searchedResult: any
}

const DidsList: React.FC<IDidsListProps> = ({}) => {
    const history = useHistory()
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const isLoading = useSelector(didsSelectors.isLoading)
    const didConfigs = useSelector(didsSelectors.getdidConfigs)

    const [pageIndex, setPageIndex] = useState(1)
    const [paginatedDids, setPaginatedDids] = useState([])
    const [dids, setDids] = React.useState<IDIDModel[]>()
    const [totalItems, setTotalItems] = useState(dids?.length)
    const selectedDidBaseId = useSelector(getSelectedDidBaseId)
    const seletedDID = useSelector(getSelectedDid)
    const didsSetup = useSelector(didsSelectors.getDids)

    const initialPurposeListState = {
        searched: '',
        searchedResult: paginatedDids?.slice(),
    }

    const [state, setState] = React.useState<ISearchState>(
        initialPurposeListState
    )
    const availableScopes = useSelector(sessionSelectors?.getAllowedScopes)
    const excededLimitsState = useSelector(licensesSelectors.excededLimits)
    const usedFeatures = useSelector(statsSelectors.getUsedFeaturesNumber)
    const statisticsIsLoading = useSelector(statsSelectors.getStatisticsLoading)

    const hasReadScope = !!(
        availableScopes && userHasScopes(didsScopes?.read, availableScopes)
    )
    const currentLicense = useSelector(licensesSelectors.getCurrentProduct)

    const [showCreateModal, setShowModal] = React.useState(false)
    const showModal = (item?: any) => {
        item ? dispatch(selectDid(item)) : null, setShowModal(true)
    }
    const hideModal = () => (dispatch(clearDid()), setShowModal(false))

    const [showDeleteWarning, setShowDeleteModal] = React.useState(false)
    const showDeleteModal = (item) => {
        dispatch(selectDid(item))
        setShowDeleteModal(true)
    }
    const hideDeleteModal = () => {
        dispatch(clearDid())
        setShowDeleteModal(false)
    }

    const [showDeleteTypeWarning, setShowDeleteTypeModal] =
        React.useState(false)
    const showDeleteTypeModal = (item) => {
        dispatch(selectDid(item))
        setShowDeleteTypeModal(true)
    }
    const hideDeleteTypeModal = () => {
        dispatch(clearDid())
        setShowDeleteTypeModal(false)
    }

    useEffect(() => {
        if (hasReadScope) {
            dispatch(getCatalog())
            dispatch(getDids())
        }
        // Just in cloud for now
        if (!statisticsIsLoading && getIsActiveSession()) {
            dispatch(getStatistics())
        }
    }, [])

    useEffect(() => {}, [didsSetup])

    useEffect(() => {
        setDids(didConfigs)
        setState(initialPurposeListState)
    }, [didConfigs, pageIndex])

    useEffect(() => {
        if (!!dids) {
            setPaginatedDids(paginate(dids, defaultPageSize, pageIndex))
        }
    }, [dids])

    useEffect(() => {
        if (!!dids) {
            setState(initialPurposeListState)
            setTotalItems(dids?.length)
        }
    }, [paginatedDids])

    const handlePageChange = (page) => {
        setPageIndex(page)
        setPaginatedDids(paginate(dids, defaultPageSize, page))
        setState(initialPurposeListState)
    }

    const handleSearchedChange = (items) => {
        if (!!items) {
            setState({
                searched: state.searched,
                searchedResult: paginate(items, defaultPageSize, pageIndex),
            })
            setTotalItems(items.length)
        }
    }

    const selectDidHandler = (item) => {
        dispatch(selectDid(item))
        history.push('/config-did')
    }

    const deleteSelectedDid = (item) => {
        // Just in cloud for now
        dispatch(deleteDid(item?.DidId))
        hideDeleteModal()
    }

    const removeType = (item, type) => {
        // Just in cloud for now
        if (type === 'dids.issuer') {
            dispatch(deleteIssuerType(item?.DidId))
        } else {
            dispatch(deleteVerifierType(item?.DidId))
        }
    }

    const addType = (item, type) => {
        if (type === 'dids.issuer') {
            // Just in cloud for now
            dispatch(addIssuerType(item?.DidId))
        } else {
            // Just in cloud for now
            dispatch(addVerifierType(item?.DidId))
        }
    }

    const limitsAreExceded =
        excededLimitsState?.length &&
        excededLimitsState?.includes(errorPanelReachedFeatures.dids)

    const limitsAreReached =
        usedFeatures?.dids === getTierLimitsValues(currentLicense)?.dids

    const deleteModalButtons = {
        primary: { label: 'public.delete', function: deleteSelectedDid },
        secondary: { label: 'public.cancel', function: hideDeleteModal },
    }

    const deleteTypeModalButtons = {
        primary: { label: 'public.delete', function: removeType },
        secondary: { label: 'public.cancel', function: hideDeleteTypeModal },
    }

    const canEdit = !!(
        availableScopes && userHasScopes(didsScopes?.edit, availableScopes)
    )
    const canDelete = !!(
        availableScopes && userHasScopes(didsScopes?.delete, availableScopes)
    )

    const itemActions = [
        {
            label: 'public.edit',
            action: showModal,
            hasScope: canEdit,
        },
        {
            label: 'public.delete',
            action: showDeleteModal,
            hasScope: canDelete,
            color: 'red',
        },
    ]

    const paginationData = {
        currentPage: pageIndex,
        totalPages: getTotalPageItems(totalItems || 0, defaultPageSize) || 0,
        onChange: handlePageChange,
        totalItems: totalItems || 0,
        pageSize: defaultPageSize,
        page: pageIndex,
        className: 'marginTop32',
    }

    return (
        <>
            <ScreenHeader
                title={'dids.screenTitle'}
                subText="dids.description"
                buttonScopes={didsScopes?.create}
                button={{
                    ...headerButton,
                    disabled: !!limitsAreExceded || limitsAreReached,
                    disabledTooltip: limitsAreReached,
                }}
                buttonFunction={showModal}
            />

            {hasReadScope ? (
                <>
                    <SearchableListHeader
                        title={''}
                        isLoading={isLoading}
                        options={dids}
                        propertiesToSearch={propertiesToSearch}
                        handleSearchedChange={handleSearchedChange}
                    />

                    {!isLoading ? (
                        !!dids && dids?.length && !!dids[0] ? (
                            <>
                                <DidsTable
                                    tableData={state.searchedResult}
                                    headerData={headersDidsTable}
                                    showingModal={
                                        showDeleteWarning ||
                                        showDeleteTypeWarning
                                    }
                                    mobileItem={(item) => (
                                        <BasicCard
                                            item={item}
                                            name={
                                                item?.alias
                                                    ? item?.alias
                                                    : item?.id
                                            }
                                            description={{
                                                text: shortValue(
                                                    item?.DidId,
                                                    15,
                                                    5
                                                ),
                                            }}
                                            actions={itemActions}
                                            buttonFunction={() => {}}
                                        />
                                    )}
                                    paginationData={paginationData}
                                    deleteItem={showDeleteModal}
                                    editItem={showModal}
                                    removeType={showDeleteTypeModal}
                                    addType={addType}
                                    noResults={
                                        dids &&
                                        dids?.length > 0 &&
                                        !(
                                            state.searchedResult &&
                                            state.searchedResult?.length
                                        )
                                    }
                                />

                                {showDeleteWarning ? (
                                    <WarningModal
                                        primaryButton={
                                            deleteModalButtons.primary
                                        }
                                        secondaryButton={
                                            deleteModalButtons.secondary
                                        }
                                        hideModal={hideDeleteModal}
                                        item={seletedDID}
                                    />
                                ) : null}
                                {showDeleteTypeWarning ? (
                                    <WarningModal
                                        title={'dids.areSureDeleteType'}
                                        description={'dids.deleteTypeWarning'}
                                        primaryButton={
                                            deleteTypeModalButtons.primary
                                        }
                                        secondaryButton={
                                            deleteTypeModalButtons.secondary
                                        }
                                        hideModal={hideDeleteTypeModal}
                                        item={seletedDID}
                                    />
                                ) : null}
                            </>
                        ) : (
                            <Description text={t('dids.noDidsCreated')} />
                        )
                    ) : (
                        <TableSkeleton
                            columnsNumber={3}
                            rowsNumber={defaultPageSize}
                            responsiveCards={true}
                        />
                    )}
                    {showCreateModal ? (
                        <DidModal
                            show={showCreateModal}
                            hideModal={hideModal}
                            dids={dids}
                        />
                    ) : null}
                </>
            ) : (
                <>
                    <PermissionsPanel scopes={didsScopes?.read} readScope />
                </>
            )}
        </>
    )
}

export default DidsList
