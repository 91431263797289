import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { licensesSelectors } from '..'
import SwitchButton from '../../../../Components/atoms/buttons/SwitchButton/SwicthButton'
import ScreenHeader from '../../../../Components/molecules/headers/ScreenHeader/ScreenHeader'
import InfoPanelWithAction from '../../../../Components/molecules/notification/panels/info/infoPanelWithAction/InfoPanelWithAction'
import CardListSkeleton from '../../../../Components/molecules/skeletons/cardListSkeleton/CardListSkeleton'
import LicenseCard from '../../../../Components/organisms/Cards/LicenseCard/LicenseCard'
import { formatStringDate } from '../../../../utils/formatDataUtil'
import Limits from '../../../studio/Home/HomeComponents/limits/Limits'
import {
    hideScreenLoaderAction,
    showScreenLoaderAction,
} from '../../Loader/loaderActions'
import { screenLoaderActive } from '../../Loader/loaderSelectors'
import { setSelectedRoute } from '../../PanelScafolding/scafoldingActions'
import { sessionSelectors } from '../../session'
import { statsSelectors } from '../../Statistics'
import { getStatistics } from '../../Statistics/statisticsActions'
import {
    createSubscriptionSteps,
    onboardingSwitchPeriodButtonOptions,
    subscriptionSteps,
    switchPeriodButtonOptions,
} from '../licenses.constants'
import {
    cancelDowngradeAction,
    cancelSubscriptionAction,
    getTiersAction,
    getSubscriptionInfoAction,
    requestSubscriptionAction,
    requestSubscriptionActionSuccess,
    resetRequestSubscriptionStatusAction,
    setCurrentTierAction,
    setCurrentStepAction,
    setNewLicenseAction,
    setSelectedPeriodAction,
    updateLicenseAction,
} from '../licensesActions'
import FeaturesModal from './changeSubscription/featuresModal/FeaturesModal'
import SureDowngradeCancelModal from './downgradeAreSureModal/DowngradeAreSureModal'
import DowngradeInfo from './downgradeInfo/DowngradeInfo'
import styles from './licenses.module.scss'
import {
    getIsActiveSession,
    userHasScopes,
} from '../../../../utils/permissionsUtil'
import { noPaymentMethod, onPremise } from '../../../../data/globalVar'
import cx from 'classnames'
import SuccessFilledPanel from '../../../../Components/molecules/notification/panels/success/errorFilledPanel/SuccessFilledPanel'
import { getStatisticHasFailed } from '../../Statistics/statisticsSelectors'
import {
    getIfCancelSubscription,
    getIfProductHasTrialDays,
    getIfTierHasInterval,
    getPrice,
    getPriceId,
    getSiwtchIntervalPeriodOptions,
    getTiersLicenses,
    isAnUpgrade,
    licenseIsLowerThanCurrent,
} from '../../../../utils/licensesUtil'
import { getPreselectedLicense } from '../licensesSelectors'
import { createDid, getDids } from '../../../studio/Dids/didActions'
import { didsSelectors } from '../../../studio/Dids'
import {
    ActionStatusTypes,
    brandPaymentMethod,
} from '../../../../assets/globalConstants'
import { firstDid } from '../../../studio/Dids/views/didList/didList.constants'
import DownGradeInfoModal from './changeSubscription/downGradeInfoModal/DownGradeInfoModal'
import RequestSubscriptionChangeModal from '../../../../Components/molecules/modals/RequestSubscriptionChange/RequestSubscriptionChangeModal'
import { DataRequestModel } from '../../../../interfaces/interfaces'
import ErrorPanelWithAction from '../../../../Components/molecules/notification/panels/error/errorPanelWithAction/ErrorPanelWithAction'
import { isDefaultBrandActive } from '../../../../utils/brandThemeUtil'

type ISubscriptionsProps = {}

const Subscriptions: React.FC<ISubscriptionsProps> = ({}) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const history = useHistory()

    // Variables from selectors
    const uRole = useSelector(sessionSelectors.getUserRole_s)
    const userIsOrgOwner = uRole === 'OrganizationOwner'
    const orgId = useSelector(sessionSelectors.getUserOrganization)
    const subscriptionInfoIsLoading = useSelector(
        licensesSelectors.getSubscriptionInfoIsLoading
    )
    const userLicense = useSelector(sessionSelectors.getLicenses)

    const tiers = useSelector(licensesSelectors.getProviderTiersAndFeatures)

    const currentLicense = useSelector(licensesSelectors.getCurrentProduct)
    const newLicense = useSelector(licensesSelectors.getNewLicense)
    const newLicensePeriod = useSelector(licensesSelectors.getNewLicensePeriod)
    const selectedPeriod = useSelector(licensesSelectors.getSelectedPeriod)
    const subscriptionInfo = useSelector(licensesSelectors.getSubscriptionInfo)
    const userPaymnetByStripe =
        !!isDefaultBrandActive() &&
        !noPaymentMethod &&
        subscriptionInfo?.currentPriceId &&
        !subscriptionInfo?.paymentOutsideStripe
    const subscriptionIsUpdating = useSelector(
        licensesSelectors.updatingSubscriptionLoading
    )
    const subscriptionIsBeingCancelled = useSelector(
        licensesSelectors.cancelSubscriptionLoading
    )
    const downgradeIsBeingCancelled = useSelector(
        licensesSelectors.cancelDowngradeLoading
    )
    const currentSubscriptionPeriod = useSelector(
        licensesSelectors.getCurrentSubscriptionPeriod
    )
    const showActivatedAccountPanel = useSelector(
        licensesSelectors.showActivatedAccountPanel
    )
    const statisticHasFailed = useSelector(getStatisticHasFailed)
    const availableScopes = useSelector(sessionSelectors?.getAllowedScopes)
    const dids = useSelector(didsSelectors.getDids)
    const creatingDidStatus = useSelector(didsSelectors.getStatus)
    const usedFeatures = useSelector(statsSelectors.getUsedFeaturesNumber)
    const statisticsIsLoading = useSelector(statsSelectors.getStatisticsLoading)
    const loaderScreenActive = useSelector(screenLoaderActive)
    const tiersAreLoading = useSelector(licensesSelectors.tiersAreLoading)

    const currentLicenseType = tiers?.find(
        (tier) => tier.id === subscriptionInfo?.currentTierId
    )
    const newLicenseDate = formatStringDate(
        subscriptionInfo?.currentPeriodEnd,
        'DD/MM/YYYY'
    )
    const newLicenseSelectedPriceId = getPriceId(newLicense, selectedPeriod)
    const subscriptionScheduleTypeSameAsCurrent =
        subscriptionInfo?.subscriptionSchedule?.scheduledTierId ===
        currentLicense?.id
    const currentSubscriptionPeriodIsYear = currentSubscriptionPeriod === 'year'
    const scheduleType =
        subscriptionInfo?.subscriptionSchedule?.scheduledTierId?.toLowerCase()
    const canReadDids = !!(
        availableScopes &&
        userHasScopes(['readDids', 'readTenants'], availableScopes, true)
    )

    // Component states
    const [showDowngradeDone, setShowDowngradeDoneModal] = React.useState(false)
    const [showDowngradeInfo, setShowDowngradeInfoScreen] =
        React.useState(false)
    const [switchPeriodValue, setmonthlyChecked] = useState(
        selectedPeriod || 'month'
    )

    const [showAllFeatures, setShowAllFeaturesModal] = React.useState(false)
    const showAllFeaturesModal = () => setShowAllFeaturesModal(true)
    const hideAllFeaturesModal = () => setShowAllFeaturesModal(false)

    const requestSubStatus = useSelector(
        licensesSelectors.getRequestSubscritptionStatus
    )
    const [showRequestSubModal, setShowRequestSubModal] = React.useState(false)
    const [showSuccessModal, setShowSuccessModal] = React.useState(false)
    const [selectedLicenseForReq, setSelectedLicenseForReq] = React.useState('')
    const [loadingReq, setLoadingReq] = React.useState(false)
    const [successReq, setSuccessReq] = React.useState(false)
    useEffect(() => {
        if (requestSubStatus === ActionStatusTypes?.success) {
            setShowRequestSubModal(true)
            setShowSuccessModal(true)
            setLoadingReq(false)
        }
        if (requestSubStatus === ActionStatusTypes?.loading) {
            setShowSuccessModal(false)
            setLoadingReq(true)
        }
        if (requestSubStatus === ActionStatusTypes?.failed) {
            setShowRequestSubModal(false)
            setShowSuccessModal(false)
            setLoadingReq(false)
        }
    }, [requestSubStatus])

    useEffect(() => {
        if (
            !!showActivatedAccountPanel &&
            userIsOrgOwner &&
            !dids &&
            creatingDidStatus !== ActionStatusTypes.loading
        ) {
            !onPremise && dispatch(createDid(firstDid))
        }
    }, [showActivatedAccountPanel, dids])

    // Functions
    const resetRequestSubscriptionStatus = () =>
        dispatch(resetRequestSubscriptionStatusAction())

    const cancelDowngrade = () => {
        dispatch(showScreenLoaderAction())
        !onPremise && dispatch(cancelDowngradeAction())
    }

    const wantsCancelSubscription = getIfCancelSubscription(
        newLicense,
        currentSubscriptionPeriod,
        selectedPeriod,
        subscriptionInfo
    )

    const downgradeCancelFunction = () => {
        if (currentLicenseType?.id && newLicenseSelectedPriceId && orgId) {
            if (!onPremise) {
                dispatch(showScreenLoaderAction())
                wantsCancelSubscription
                    ? dispatch(cancelSubscriptionAction())
                    : dispatch(
                          updateLicenseAction(
                              newLicense,
                              newLicenseSelectedPriceId,
                              orgId
                          )
                      )
            }

            setShowDowngradeInfoScreen(false)
        }
    }

    const selectPeriod = (period: string) => {
        setmonthlyChecked(period)
        dispatch(setSelectedPeriodAction(period))
    }

    const cancelSubscription = () => {
        setShowDowngradeDoneModal(true)
    }

    const licenseIsIncomplete = !!(
        !onPremise && subscriptionInfo?.subscriptionStatus === 'incomplete'
    )
    const canceledLicense =
        subscriptionInfo?.subscriptionStatus === 'unpaid' ||
        subscriptionInfo?.subscriptionStatus === 'canceled'

    const selectLicenseAndNavigate = (license) => {
        navigateChangeSubscription()
        selectLicense(license)
    }

    const selectLicense = (license) => {
        dispatch(setNewLicenseAction(license))
    }

    const navigateChangeSubscription = () => {
        if (
            !subscriptionIsUpdating &&
            !subscriptionIsBeingCancelled &&
            !downgradeIsBeingCancelled
        ) {
            dispatch(setSelectedRoute('change-subscription'))
            history.push('change-subscription')
        }
    }

    function requestSubscription(data: DataRequestModel) {
        const { priceId, orgId, message } = data
        setLoadingReq(true)
        !onPremise &&
            dispatch(requestSubscriptionAction(priceId, orgId, message))
    }

    useEffect(() => {
        if (
            loaderScreenActive &&
            !subscriptionInfoIsLoading &&
            !subscriptionIsBeingCancelled &&
            !downgradeIsBeingCancelled &&
            !subscriptionIsUpdating
        ) {
            dispatch(hideScreenLoaderAction())
        }
        if (!subscriptionInfoIsLoading && !subscriptionInfo?.currentTierId) {
            setTimeout(() => {
                // Just in cloud for now
                !onPremise &&
                    userPaymnetByStripe &&
                    dispatch(getSubscriptionInfoAction())
            }, 2000)
        }
        if (tiers && !currentLicense?.id) {
            if (subscriptionInfo?.currentTierId?.length) {
                const currentLicense = tiers?.find((el) => {
                    return (
                        el?.id?.toLowerCase() ===
                        subscriptionInfo?.currentTierId?.toLowerCase()
                    )
                })
                dispatch(setCurrentTierAction(currentLicenseType))
            } else if (userLicense && userLicense[0]?.licenseType) {
                const currentLicense = tiers?.find((el) => {
                    return (
                        el?.licenseType?.toLowerCase() ===
                        userLicense[0]?.licenseType?.toLowerCase()
                    )
                })
                dispatch(setCurrentTierAction(currentLicenseType))
            }
        }
    }, [tiers, tiers?.length])

    useEffect(() => {
        if (!tiers?.length && getIsActiveSession()) {
            // Just in cloud for now
            !onPremise && dispatch(getTiersAction())
        }
        if (!usedFeatures && getIsActiveSession()) {
            !onPremise && dispatch(getStatistics())
        }
    }, [currentLicense])

    useEffect(() => {
        canReadDids && dispatch(getDids())
        setCurrentStepAction(flowSteps[0])
    }, [])

    useEffect(() => {}, [subscriptionInfo])

    const tokenOrg = useSelector(sessionSelectors.getUserOrganization)
    const preselectLicense = useSelector(getPreselectedLicense)

    const getSwitchPeriodOptions = getSiwtchIntervalPeriodOptions(
        onboardingSwitchPeriodButtonOptions,
        tiers
    )

    const trialLicenseIncomplete =
        !tokenOrg?.length && preselectLicense === 'Starter'

    const isNewUser = !!(
        availableScopes &&
        userHasScopes(['createOrganization'], availableScopes)
    )
    const flowSteps = isNewUser ? createSubscriptionSteps : subscriptionSteps

    useEffect(() => {
        setCurrentStepAction(flowSteps[0])
    })
    useEffect(() => {}, [tiers])

    return (
        <React.Fragment>
            {!showDowngradeInfo ? (
                <>
                    <ScreenHeader
                        title={'subscription.title'}
                        subText={t('subscription.description')}
                        hideBorderBottom={true}
                        className={cx('marginBottom30')}
                    />
                    {subscriptionInfo &&
                    !!subscriptionInfo.subscriptionSchedule?.scheduledTierId ? (
                        <>
                            <InfoPanelWithAction
                                title={t('subscription.reminderChange', {
                                    date: newLicenseDate,
                                })}
                                label={
                                    subscriptionScheduleTypeSameAsCurrent
                                        ? t('subscription.newWillBe', {
                                              type: currentLicense?.name,
                                              period: t(
                                                  currentSubscriptionPeriodIsYear
                                                      ? 'subscription.monthly'
                                                      : 'subscription.yearly'
                                              ),
                                          })
                                        : t('subscription.lessFeatures', {
                                              type: newLicense?.name,
                                              period: newLicenseDate,
                                          })
                                }
                                label2={
                                    userIsOrgOwner && userPaymnetByStripe
                                        ? 'subscription.havechangeMind'
                                        : ''
                                }
                                label3={
                                    userIsOrgOwner && userPaymnetByStripe
                                        ? t('subscription.stillHaveTime', {
                                              date: newLicenseDate,
                                          })
                                        : ''
                                }
                                extraTranslationParams={{ date: '01/02/23' }}
                                hasIcon={true}
                                button={
                                    userIsOrgOwner && userPaymnetByStripe
                                        ? {
                                              label: 'subscription.keepCurrent',
                                              function: cancelDowngrade,
                                          }
                                        : undefined
                                }
                            />
                        </>
                    ) : null}
                    {subscriptionInfo &&
                    !!subscriptionInfo.cancelAtPeriodEnd ? (
                        <>
                            <ErrorPanelWithAction
                                title={t(
                                    'subscription.subscriptionCanceledTitle',
                                    {
                                        date: newLicenseDate,
                                    }
                                )}
                                label={t(
                                    'subscription.subscriptionCanceledLabel1',
                                    {
                                        date: newLicenseDate,
                                    }
                                )}
                                label2={
                                    userIsOrgOwner && userPaymnetByStripe
                                        ? 'subscription.havechangeMind'
                                        : ''
                                }
                                label3={
                                    userIsOrgOwner && userPaymnetByStripe
                                        ? t('subscription.stillTimeKeep', {
                                              date: newLicenseDate,
                                          })
                                        : ''
                                }
                                extraTranslationParams={{ date: '01/02/23' }}
                                hasIcon={true}
                                button={
                                    userIsOrgOwner && userPaymnetByStripe
                                        ? {
                                              label: 'subscription.keep',
                                              function: cancelDowngrade,
                                          }
                                        : undefined
                                }
                            />
                        </>
                    ) : null}
                    {showActivatedAccountPanel &&
                    !licenseIsIncomplete &&
                    !subscriptionInfo.cancelAtPeriodEnd &&
                    !canceledLicense ? (
                        <SuccessFilledPanel
                            title={'subscription.accountActivated'}
                            label={'subscription.accountActivatedDesc'}
                        />
                    ) : null}
                    {usedFeatures && (
                        <Limits
                            licenseIsIncomplete={licenseIsIncomplete}
                            trialLicenseIsIncomplete={trialLicenseIncomplete}
                            statisticHasFailed={statisticHasFailed}
                            userLicense={userLicense}
                            tiers={tiers}
                            currentLicense={currentLicense}
                            subscriptionInfo={subscriptionInfo}
                            subscriptionInfoIsLoading={
                                subscriptionInfoIsLoading
                            }
                            subscriptionIsUpdating={subscriptionIsUpdating}
                            statisticsIsLoading={statisticsIsLoading}
                            subscriptionIsBeingCancelled={
                                subscriptionIsBeingCancelled
                            }
                            downgradeIsBeingCancelled={
                                downgradeIsBeingCancelled
                            }
                            loaderScreenActive={loaderScreenActive || false}
                            currentSubscriptionPeriod={
                                currentSubscriptionPeriod
                            }
                            currentLicenseType={currentLicenseType}
                            newLicenseDate={newLicenseDate}
                            currentSubscriptionPeriodIsYear={
                                currentSubscriptionPeriodIsYear
                            }
                            usedFeatures={usedFeatures}
                            tiersAreLoading={tiersAreLoading}
                        />
                    )}
                    <div className={styles.availablePlantsHeader}>
                        <p className={cx('heading5 neutral1000')}>
                            {t('subscription.availablePlans')}
                        </p>
                        {!!(getSwitchPeriodOptions?.length > 1) ? (
                            <SwitchButton
                                checkedValue={switchPeriodValue || ''}
                                options={switchPeriodButtonOptions}
                                onChangeSwitchSelect={selectPeriod}
                            />
                        ) : null}
                    </div>
                    {tiers?.length &&
                    !!subscriptionInfo &&
                    currentLicenseType?.id ? (
                        <>
                            <div className={styles.licenses}>
                                {tiers?.map((tier) => {
                                    return getIfTierHasInterval(
                                        tier,
                                        getSwitchPeriodOptions,
                                        switchPeriodValue
                                    ) || !tier?.prices?.length ? (
                                        <LicenseCard
                                            subscriptionInfo={subscriptionInfo}
                                            getPrice={getPrice}
                                            currentLicensePeriod={
                                                currentSubscriptionPeriod || ''
                                            }
                                            newLicensePeriod={
                                                newLicensePeriod || ''
                                            }
                                            noPaymentAction={() => (
                                                setSelectedLicenseForReq(tier),
                                                setShowRequestSubModal(true)
                                            )}
                                            showAllDetails
                                            userRole={uRole}
                                            license={tier}
                                            firstButton={
                                                subscriptionInfo &&
                                                subscriptionInfo?.currentTierId
                                                    ?.length &&
                                                !licenseIsIncomplete
                                                    ? {
                                                          label: '',
                                                          function: () => {
                                                              !!getIfProductHasTrialDays(
                                                                  tier,
                                                                  selectedPeriod
                                                              ) &&
                                                              licenseIsLowerThanCurrent(
                                                                  tier,
                                                                  currentLicense,
                                                                  currentSubscriptionPeriod,
                                                                  selectedPeriod
                                                              )
                                                                  ? //   !(
                                                                    //       currentLicenseType ===
                                                                    //           'Starter' &&
                                                                    //       selectedPeriod ===
                                                                    //           'year'
                                                                    //   ) ||
                                                                    //   isLowerThanCurrent(newLicense)
                                                                    (selectLicense(
                                                                        tier
                                                                    ),
                                                                    setShowDowngradeDoneModal(
                                                                        true
                                                                    ))
                                                                  : selectLicenseAndNavigate(
                                                                        tier
                                                                    )
                                                          },
                                                      }
                                                    : undefined
                                            }
                                            downgradeSubscriptionFunction={(
                                                tier
                                            ) => selectLicenseAndNavigate(tier)}
                                            // COMEMNTED because this will be restored in the future
                                            // secondButton={{
                                            //     label: 'subscription.seeAllFeatures',
                                            //     function: showAllFeaturesModal,
                                            // }}
                                            period={
                                                getSwitchPeriodOptions?.length >
                                                1
                                                    ? switchPeriodValue
                                                    : getSwitchPeriodOptions[0]
                                                          ?.value || 'month'
                                            }
                                            isCurrentLicense={
                                                subscriptionInfo?.currentTierId ===
                                                tier?.id
                                            }
                                            cancelSubscriptionFunction={(
                                                tier
                                            ) => {
                                                selectLicense(tier),
                                                    cancelSubscription()
                                            }}
                                            lowerThanCurrent={licenseIsLowerThanCurrent(
                                                tier,
                                                currentLicense,
                                                currentSubscriptionPeriod,
                                                selectedPeriod
                                            )}
                                            isNewLicense={false}
                                            tiers={tiers?.length}
                                        />
                                    ) : null
                                })}
                            </div>
                        </>
                    ) : (
                        <div className={styles.licenses}>
                            <CardListSkeleton
                                cardsNumber={5}
                                className={styles.licenseSkeleton}
                            />
                        </div>
                    )}
                    {showAllFeatures ? (
                        <FeaturesModal
                            show={!!showAllFeatures}
                            hideModal={hideAllFeaturesModal}
                            switchPeriodValue={switchPeriodValue}
                            setPeriodChecked={selectPeriod}
                        />
                    ) : null}
                    {showDowngradeDone ? (
                        <SureDowngradeCancelModal
                            hideModal={() => {
                                setShowDowngradeDoneModal(false),
                                    !wantsCancelSubscription
                                        ? setShowDowngradeInfoScreen(true)
                                        : downgradeCancelFunction()
                            }}
                            newLicense={newLicense}
                            currentSubscriptionPeriod={
                                currentSubscriptionPeriod
                            }
                            selectedPeriod={selectedPeriod}
                            subscriptionInfo={subscriptionInfo}
                            // cancelling={wantsCancelSubscription}
                            closeModal={() => {
                                setShowDowngradeDoneModal(false)
                            }}
                            show={showDowngradeDone}
                        />
                    ) : null}
                    {showRequestSubModal ? (
                        <RequestSubscriptionChangeModal
                            show={showRequestSubModal}
                            currentLicense={currentLicense}
                            currentPeriod={switchPeriodValue}
                            hideModal={() => (
                                setShowRequestSubModal(false),
                                resetRequestSubscriptionStatus(),
                                setSuccessReq(false),
                                setLoadingReq(false)
                            )}
                            licenses={tiers}
                            selectedLicense={selectedLicenseForReq}
                            selectedPeriodChecked={switchPeriodValue}
                            setPeriodChecked={selectPeriod}
                            submitData={(x?) => requestSubscription(x)}
                            loading={loadingReq && !showSuccessModal}
                        />
                    ) : null}
                </>
            ) : (
                // <DowngradeInfo
                //     backFunction={() => {
                //         setShowDowngradeInfoScreen(false)
                //     }}
                //     continueFunction={downgradeCancelFunction}
                // />
                //    {showDowngradeInfoModal ? (
                <>
                    <DownGradeInfoModal
                        backFunction={() => {
                            setShowDowngradeInfoScreen(false)
                        }}
                        continueFunction={downgradeCancelFunction}
                        showAllFeaturesModal={showAllFeaturesModal}
                        currentLicense={currentLicense}
                        show={showDowngradeInfo}
                        usedFeatures={usedFeatures}
                    />
                    {showAllFeatures ? (
                        <FeaturesModal
                            show={!!showAllFeatures}
                            switchPeriodValue={switchPeriodValue}
                            hideModal={hideAllFeaturesModal}
                            setPeriodChecked={selectPeriod}
                        />
                    ) : null}
                </>
            )}
        </React.Fragment>
    )
}

Subscriptions.defaultProps = {}

export default Subscriptions
