import * as React from 'react'
import cx from 'classnames'
import styles from './cardLeftColumn.module.scss'
import { t } from 'i18next'

export type ICardLeftColumnProps = {
    text: string
    information?: string
    extraTranslationParams?: Object
}

export const CardLeftColumn: React.FC<ICardLeftColumnProps> = (props) => {
    const { text, information, extraTranslationParams } = props
    return (
        <div key={0} className={styles?.card__row}>
            <div>
                <p className={`${cx('bodyRegularSM neutral1000')}`}>
                    {t(text)}
                </p>
            </div>
        </div>
    )
}

export default CardLeftColumn
