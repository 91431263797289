import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { credentialsSelectors } from '../..'
import ScreenHeader from '../../../../../Components/molecules/headers/ScreenHeader/ScreenHeader'
import { userHasScopes } from '../../../../../utils/permissionsUtil'
import { sessionSelectors } from '../../../../common/session'
import { credentialsScopes } from './credentialsList.constants'
import IssuedCreds from '../../tabsContent/issuedCredentials/IssuedCreds'
import PendingCreds from '../../tabsContent/pendingCredentials/pendingCreds'
import Tabs from '../../../../../Components/Tabs/Tabs'
import { licensesSelectors } from '../../../../common/licenses'
import { statsSelectors } from '../../../../common/Statistics'
import { errorPanelReachedFeatures } from '../../../../common/PanelScafolding/private/panelScafoldingPrivateConstants'
import { getTierLimitsValues } from '../../../../../utils/licensesUtil'
import { useLocation } from 'react-router-dom'
import { LocationStateModel } from '../../../../../interfaces/interfaces'

type ICredentialListProps = {}

const CredentialList: React.FC<ICredentialListProps> = ({}) => {
    const { t } = useTranslation()
    const location = useLocation<LocationStateModel>()

    const availableScopes = useSelector(sessionSelectors?.getAllowedScopes)
    const hasReadScope = !!(
        availableScopes &&
        userHasScopes(credentialsScopes?.read, availableScopes)
    )

    const pendingCredentialsData = useSelector(
        credentialsSelectors.getPendingCredentialsDataList
    )
    const currentLicense = useSelector(licensesSelectors.getCurrentProduct)

    const excededLimitsState = useSelector(licensesSelectors.excededLimits)
    const usedFeatures = useSelector(statsSelectors.getUsedFeaturesNumber)
    const limitsAreExceded =
        excededLimitsState?.length &&
        excededLimitsState?.includes(errorPanelReachedFeatures.credentials)

    const limitsAreReached =
        usedFeatures?.issuedCredentials ===
        getTierLimitsValues(currentLicense)?.issuedCredentials

    // Tab Sections
    const detailSections = [
        {
            id: '1',
            tabTitle: t('credentials.issuedCredentials'),
            tabContent: <IssuedCreds />,
        },
        {
            id: '2',
            tabTitle: t('credentials.pendingCredentials'),
            tabContent: <PendingCreds />,
            badget: hasReadScope ? pendingCredentialsData?.total || '0' : 'x',
            disabled: limitsAreExceded || limitsAreReached,
            info:
                limitsAreExceded || limitsAreReached
                    ? t('subscription.limitReachedDescription', {
                          feature: t(
                              'subscription.limitedFeatures.issuedCredentials'
                          ),
                      })
                    : undefined,
        },
    ]

    return (
        <>
            <ScreenHeader
                title={'credentials.title'}
                hideBorderBottom={true}
                titleClassname="heading5"
            />

            {/* { TODO: Uncomment when BE Search is available */
            /* <SearchableListHeader
                title={'credentials.description'}
                isLoading={isLoading}
                options={creds}
                propertiesToSearch={propertiesToSearch}
                handleSearchedChange={handleSearchedChange}
            /> */}

            <>
                <Tabs
                    sections={detailSections}
                    className={'marginTop32'}
                    selected={location && location?.state ? 1 : 0}
                />
            </>
        </>
    )
}

export default CredentialList
