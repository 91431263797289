import * as React from 'react'
import cx from 'classnames'
import { t } from 'i18next'

export type IQuantityDataCellProps = {
    data: any
}

const QuantityDataCell: React.FC<IQuantityDataCellProps> = (props) => {
    const { data } = props
    return (
        <>
            <td className={`${cx('bodyBoldSM')}`}>
                {data === '∞' ? t('subscription.unlimited') : data}
            </td>
        </>
    )
}

export default QuantityDataCell
